/**
 * Function to repopulate the freight fields once the /verify_freight_details endpoint
 * has been called
 * @param {object} data - data comming from thr /verify_freight_details endpoint
 * @param {array} freightLines - list of freight lines
 */
export const refillFreightData = ({ data }) => {
  const updatedFreightData = {
    prepaidCode: "N",
    prepaid: false,
  };

  if (data.length > 0) {
    data.forEach((element) => {
      if (element.field === "freight.prepaid_code") {
        updatedFreightData.prepaidCode = element.value;

        if (element.value === "Y") {
          updatedFreightData.prepaid = true;
        }

        if (element.value === "N" || element.value === "3") {
          updatedFreightData.prepaid = false;
        }
      }
    });
  }

  return updatedFreightData;
};

export const verifyFreightLine = (freightDetails) => {
  let freightLineId = 0;
  if (freightDetails.length > 0) {
    freightDetails.forEach((element) => {
      if (element.field === "freight.freight_line_id") {
        freightLineId = element.value;
      }
    });
  }
  return freightLineId;
};
