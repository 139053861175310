import { memo, useCallback, useEffect, useState } from "react";
import Table from "../../atoms/Table/Table";
import Paginator from "../../atoms/Paginator/Paginator";

/**
 *  Memo function to avoid this component rerender at least that the list
 *  or one of the props has been updated
 */
function areEqual(prevProps, nextProps) {
  const productssAreEqual = prevProps.products?.find(
    (prev, index) => prev?.id === nextProps.products[index]?.id,
  );

  if (
    prevProps.setSelectedId === nextProps.setSelectedId &&
    prevProps.total === nextProps.total &&
    prevProps.selectedId === nextProps.selectedId &&
    prevProps.page === nextProps.page &&
    prevProps.setPage === nextProps.setPage &&
    productssAreEqual
  ) {
    return true;
  }
  return false;
}

function XRefTable({
  setSelectedId,
  total,
  products,
  selectedId,
  page,
  setPage,
}) {
  const [dataList, setDataList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  useEffect(() => {
    setDataList(
      products?.map((item) => {
        const parts = item.product.split("/");
        const productCode = parts[parts.length - 2];
        return {
          id: productCode,
          referenceCode: item.reference_code,
          product: productCode,
          dealerPrice: item.dealer_price,
          description: item.description,
        };
      }),
    );
  }, [products]);

  const onSelectRow = useCallback(
    (id, data) => {
      setSelectedId(id, data);
    },
    [setSelectedId],
  );

  return (
    <>
      <div className="xref-popup__table mt-5">
        <Table
          selector="raddio"
          headerAttributes={[
            {
              id: "select",
              label: "Select",
            },
            {
              id: "referenceCode",
              label: "XRef Code",
            },
            {
              id: "product",
              label: "Product Code",
            },
            {
              id: "dealerPrice",
              label: "Dealer Price",
            },
            {
              id: "description",
              label: "Description",
            },
          ]}
          hideId={true}
          dataList={dataList}
          onSelectRow={onSelectRow}
          selectedItem={selectedId}
        />
      </div>
      <Paginator
        rowsPerPage={rowsPerPage}
        totalOrders={total}
        page={page}
        setPage={(p) => setPage(p)}
      />
    </>
  );
}

export default memo(XRefTable, areEqual);
