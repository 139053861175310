import axios from "axios";
import { endpoints } from "./endpoints";
// import qs from 'qs'
// import { setCookie, deleteCookie, getCookie } from '../helpers/helperFunctions'

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

export const apiPut = {
  subOrder: updateSubOrder,
  lineItem: updateLineItem,
};

async function updateSubOrder(newSubOrder) {
  const {
    freight: {
      billOfLading,
      freightLine,
      freightAmount,
      handlingCharge,
      isDirectShip,
      prepaid,
      blindShip,
      estimatedCost,
      prepaidCode,
      freightRater: { carrierQuoteNumber, TMSQuoteNumber },
      dropShipAddress: {
        id: dropShipAddressId,
        isDropShip,
        name: dropShipAddressName,
        city: dropShipAddressCity,
        route: dropShipAddressRoute,
        state: dropShipAddressState,
        street: dropShipAddressStreet,
        zipCode: dropShipAddressZipCode,
        email: dropShipAddressEmail,
        fax: dropShipAddressFax,
        contactName: dropShipAddressContactName,
        phone: dropShipAddressPhone,
      },
    },
  } = newSubOrder;

  let dropShipInformation = null;

  if (dropShipAddressId === 0 && isDropShip) {
    dropShipInformation = {
      customer_id: newSubOrder.customer_id,
      ship_to_name: dropShipAddressName,
      address: {
        city: dropShipAddressCity,
        route: dropShipAddressRoute,
        state: dropShipAddressState,
        street: dropShipAddressStreet,
        zip_code: dropShipAddressZipCode,
      },
      contact: {
        email: dropShipAddressEmail,
        fax: dropShipAddressFax,
        name: dropShipAddressContactName,
        phone: dropShipAddressPhone,
      },
    };
  }

  const comments = [];
  if (newSubOrder.freight.comments[0])
    comments.push(newSubOrder.freight.comments[0]);
  if (newSubOrder.freight.comments[1])
    comments.push(newSubOrder.freight.comments[1]);

  const thirdPartyBilling = {
    zip_code: newSubOrder.freight.thirdPartyBilling.zipCode,
    ...newSubOrder.freight.thirdPartyBilling,
  };

  delete thirdPartyBilling.zipCode;

  const dataObject = {
    customer_order_num: newSubOrder.customer_order_num,
    customer_id: newSubOrder.customer_id,
    date_of_order: newSubOrder.date_of_order,
    customer_po: newSubOrder.customer_po,
    customer_invoice_num: newSubOrder.customer_invoice_num,
    cost: newSubOrder.cost,
    creditcard_charge: newSubOrder.creditcard_charge,
    non_a_r_amount: newSubOrder.non_a_r_amount,
    status: newSubOrder.status,
    placed_by: newSubOrder.placed_by,
    took_by: newSubOrder.took_by,
    hold_status: newSubOrder.hold_status,
    hold_date: newSubOrder.hold_date,
    special_instructions: newSubOrder.special_instructions,
    freight: {
      comments,
      bill_of_lading: billOfLading,
      freight_line_id: freightLine ? freightLine.id : 149,
      freight_amount: +freightAmount,
      handling_charge: +handlingCharge,
      is_direct_ship: isDirectShip,
      prepaid,
      is_drop_ship: isDropShip,
      drop_ship_address_id: dropShipAddressId,
      drop_ship_address: dropShipInformation,
      blind_ship: blindShip,
      prepaid_code: prepaidCode,
      estimated_cost: estimatedCost,
      ignore_shipping_rules: false,
      freightline_quote_num: carrierQuoteNumber,
      tms_quote_num: TMSQuoteNumber,
    },
    mounted_pieces: newSubOrder.mounted_pieces,
    mounted_sales: newSubOrder.mounted_sales,
    discount_type: newSubOrder.discount_type,
    discount_program_id: newSubOrder.discount_program_id,
    discount_program: {},
    discount_percent: newSubOrder.discount_percent,
    next_line_num: newSubOrder.next_line_num,
    original_took_order: newSubOrder.original_took_order,
    warehouse_id: newSubOrder.warehouse_id,
    is_bill_only: newSubOrder.is_bill_only === "Yes" ? true : false,
    is_stock_transfer: newSubOrder?.is_stock_transfer ? true : false,
    third_party_billing: thirdPartyBilling,
  };

  const response = await axios({
    method: "put",
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFTOKEN",
    withCredentials: true,
    url:
      endpoints.MITCO_API_URL +
      "/v1" +
      endpoints.SUB_ORDER_POST +
      newSubOrder.customer_order_num +
      "/",
    headers: {
      Accept: "application/json",
    },
    data: dataObject,
  });
  return response.data;
}

async function updateLineItem(lineItem, customerOrderNum) {
  const dataObject = {
    id: lineItem.id,
    product_code: lineItem.productCode,
    line_num: lineItem.ln,
    item_type: lineItem.itemType,
    description: lineItem.description,
    qty_ordered: lineItem.qrd,
    qty_backordered: lineItem.bo,
    transfer_qty: lineItem.tq,
    discount_schedule: lineItem.d,
    item_price: lineItem.item_price,
    price_type: lineItem.s,
    discount: lineItem.discount,
    po_line_num: 0,
    wheel_id: 0,
  };

  const response = await axios.put(
    endpoints.MITCO_API_URL +
      "/v1" +
      endpoints.SUB_ORDER_READ +
      customerOrderNum +
      endpoints.SUBORDER_ITEMS_CREATE +
      lineItem.id +
      "/",
    dataObject,
    {
      xsrfCookieName: "csrftoken",
      xsrfHeaderName: "X-CSRFTOKEN",
      withCredentials: true,
      headers: {
        Accept: "application/json",
      },
    },
  );

  return response;
}
