import { memo } from "react";
import InventoryOrderItem from "./InventoryOrderItem";
import DescriptionOrderItem from "./DescriptionOrderItem";
import AddonOrderItem from "./AddonOrderItem";

import {
  ORDER_ITEM_INVENTORY_TYPE,
  ORDER_ITEM_NONE_TYPE,
  ORDER_ITEM_MOUNT_TYPE,
  ORDER_ITEM_DESCRIPTION_TYPE,
  ORDER_ITEM_ADDON_TYPE,
} from "../../../helpers/const";

function OrderItem({ item, allowedActions }) {
  return (
    <>
      {[
        ORDER_ITEM_INVENTORY_TYPE,
        ORDER_ITEM_NONE_TYPE,
        ORDER_ITEM_MOUNT_TYPE,
      ].includes(item.itemType) ? (
        <InventoryOrderItem item={item} allowedActions={allowedActions} />
      ) : item.itemType === ORDER_ITEM_DESCRIPTION_TYPE ? (
        <DescriptionOrderItem item={item} allowedActions={allowedActions} />
      ) : (
        item.itemType === ORDER_ITEM_ADDON_TYPE && (
          <AddonOrderItem item={item} allowedActions={allowedActions} />
        )
      )}
    </>
  );
}

export default memo(OrderItem);
