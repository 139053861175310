import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

function ToolTip({ children, title, textColor, backgroundColor }) {
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: backgroundColor,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      color: textColor,
      backgroundColor,
    },
  }));

  return (
    <>
      <BootstrapTooltip title={title}>{children}</BootstrapTooltip>
    </>
  );
}

export default ToolTip;
