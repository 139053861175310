import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { formatFaxEmailData } from "./faxEmail";
import { apiPost } from "../../../api/apiPost";
import { apiGet } from "../../../api/apiGet";

export const sendToClient = createAsyncThunk(
  "faxEmail/sendToClient",
  async (data, thunkAPI) => {
    try {
      const storeStates = thunkAPI.getState();
      const {
        subOrder: { subOrderId },
      } = storeStates;

      const formattedData = formatFaxEmailData(data);
      await apiPost.sendOrderByEmailFax({
        faxEmailData: formattedData,
        orderId: subOrderId,
      });
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error sending the data.",
      });
    }
  },
);

export const getSentToParams = createAsyncThunk(
  "faxEmail/getSentToParams",
  async (_data, thunkAPI) => {
    try {
      const storeStates = thunkAPI.getState();
      const {
        subOrder: { subOrderId },
      } = storeStates;
      const response = await apiGet.getSentToParams(subOrderId);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error getting the saved Send Order parameters.",
      });
    }
  },
);

export const faxEmailSlice = createSlice({
  name: "faxEmailSlice",
  initialState: {},
});

export default faxEmailSlice.reducer;
