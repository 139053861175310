import { defaultOrderItemErrors } from "../api/models/ItemsModel";
import { apiGet } from "../api/apiGet";

const formatItems = async (items, subOrderStatus, type, wh) => {
  const rows = [];
  const shouldBeLocked = subOrderStatus > 2 ? true : false;

  if (type === "subOrder") {
    for (const [index, lineItem] of items.entries()) {
      let itemProductCode = "";
      let discountSchedules = [];
      let warehouses = [];

      if (lineItem?.item?.product?.product_code) {
        itemProductCode = lineItem.item.product.product_code;
        discountSchedules =
          await apiGet.discountSchedulesForProduct(itemProductCode);
        const product = await apiGet.productById(itemProductCode);
        warehouses = product.warehouses;
      } else {
        itemProductCode = null;
      }

      rows.push({
        id: lineItem.item.id,
        isSubItem: false,
        index: index,
        itemType: lineItem.item.item_type,
        ln: lineItem.item.line_num,
        productCode: itemProductCode,
        description: lineItem.item.description,
        wh: {
          id: lineItem?.item?.product?.warehouse_id,
          name: lineItem?.item?.product?.warehouse_name,
        },
        warehouse_id: lineItem?.item?.product?.warehouse_id,
        s: lineItem.item.price_type,
        d: lineItem.item.discount_schedule
          ? lineItem.item.discount_schedule
          : "Z",
        tq: parseInt(lineItem.item.transfer_qty),
        qrd: parseInt(lineItem.item.qty_ordered),
        bo: parseInt(lineItem.item.qty_backordered),
        avail: parseInt(lineItem.item?.product?.qty_available),
        item_price: Number(lineItem.item.item_price),
        discount: lineItem.item.discount,
        ext: Number(lineItem.item.price),
        weight: Number(lineItem.item.weight),
        unitWeight: Number(
          lineItem.item.weight /
            (lineItem.item.qty_ordered - lineItem.item.qty_backordered),
        ),
        wsurch: Number(lineItem.item.price) + Number(lineItem.item.surcharge),
        warehouses: warehouses,
        availableProducts: [lineItem.item?.product?.product_code],
        availableDiscounts: discountSchedules,
        hasPriceOverride: false,
        isLocked: shouldBeLocked,
        errors: defaultOrderItemErrors,
      });

      if (lineItem.subitems != null) {
        lineItem.subitems.forEach((item) => {
          rows.push({
            id: item.id,
            isSubItem: true,
            index: index,
            itemType: item.item_type,
            ln: item.line_num,
            productCode: item?.product?.product_code
              ? item.product.product_code
              : itemProductCode,
            description: item?.description,
            wh: {
              id: lineItem?.item?.product?.warehouse_id,
              name: lineItem?.item?.product?.warehouse_name,
            },
            warehouse_id: item?.product?.warehouse_id,
            s: item?.price_type,
            d: item?.discount_schedule ? item?.discount_schedule : "Z",
            tq: parseInt(item?.transfer_qty),
            qrd: parseInt(item?.qty_ordered),
            bo: parseInt(item?.qty_backordered),
            avail: parseInt(item?.product?.qty_available),
            item_price: Number(item?.item_price),
            discount: item?.discount,
            ext: Number(item?.price),
            weight: Number(item?.weight),
            unitWeight: Number(item?.weight / item?.qty_ordered),
            wsurch: Number(item?.price) + Number(item?.surcharge),
            warehouses: warehouses,
            availableProducts: [item.product?.product_code],
            availableDiscounts: discountSchedules,
            hasPriceOverride: false,
            isLocked: shouldBeLocked,
            errors: defaultOrderItemErrors,
          });
        });
      }
    }
  } else if (type === "quote") {
    items.map((lineItem, index) => {
      rows.push({
        id: lineItem.item.id,
        isSubItem: false,
        index: index,
        itemType: lineItem.item.item_type,
        ln: lineItem.item.line_num,
        productCode: lineItem.item.product_code,
        description: lineItem.item.description,
        wh: wh,
        warehouse_id: lineItem.warehouse_id,
        s: lineItem.item.price_type,
        d: lineItem.item.discount_schedule,
        tq: 0,
        qrd: lineItem.item.qty_ordered,
        bo: lineItem.item.qty_backordered,
        avail: 0,
        item_price: Number(lineItem.item.item_price),
        discount: 0,
        ext: Number(lineItem.item.price),
        weight: Number(lineItem.item.weight),
        unitWeight: Number(
          lineItem.item.weight /
            (lineItem.item.qty_ordered - lineItem.item.qty_backordered),
        ),
        wsurch: Number(lineItem.item.price) + Number(lineItem.item.surcharge),
        warehouses: [],
        availableProducts: [lineItem.item.product_code],
        availableDiscounts: [],
        hasPriceOverride: false,
        isLocked: true,
        errors: defaultOrderItemErrors,
      });

      if (lineItem.subitems != null) {
        lineItem.subitems.forEach((item) => {
          rows.push({
            id: item.id,
            isSubItem: false,
            index: index,
            itemType: item.item_type,
            ln: item.line_num,
            productCode: item.product_code,
            description: item.description,
            wh: wh,
            warehouse_id: 0,
            s: item.price_type,
            d: item.discount_schedule,
            tq: 0,
            qrd: item.qty_ordered,
            bo: item.qty_backordered,
            avail: 0,
            item_price: Number(item.item_price),
            discount: 0,
            ext: Number(item.price),
            weight: Number(item.weight),
            unitWeight: Number(
              item.weight / (item.qty_ordered - item.qty_backordered),
            ),
            wsurch: Number(item.price) + Number(item.surcharge),
            warehouses: [],
            availableProducts: [item.product_code],
            availableDiscounts: [],
            hasPriceOverride: false,
            isLocked: true,
            errors: defaultOrderItemErrors,
          });
        });
      }

      return rows;
    });
  }

  return rows.sort(function (a, b) {
    return a.ln - b.ln;
  });
};

export default formatItems;
