import {
  ORDER_ITEM_DESCRIPTION_TYPE,
  ORDER_ITEM_INVENTORY_TYPE,
  MAX_WEIGHT_OF_BOXED_PACKAGE,
  MAX_WEIGHT_OF_COMBINED_PACKAGE,
  MAX_WEIGHT_OF_SHIPPING_ITEM,
  MIN_WEIGHT_CAN_BE_COMBINED,
  INITIAL_PACKAGE,
} from "./const";

/**
 * Checks if an item is a non-subitem with weight and of inventory type.
 * @param {Object} item - The item to check.
 * @returns {boolean} - True if conditions are met, false otherwise.
 */
const isInventoryItemWithWeight = (item) =>
  !item.isSubItem && item.itemType === ORDER_ITEM_INVENTORY_TYPE && item.weight;

/**
 * Checks if the current item is mounted based on the previous item's description.
 * @param {Array} items - The list of items.
 * @param {number} currentIndex - The current index in the list.
 * @returns {boolean} - True if the previous item indicates a mounted condition, false otherwise.
 */
const isMountedItem = (items, curIndex) =>
  curIndex > 0 &&
  items[curIndex - 1].isSubItem &&
  items[curIndex - 1].itemType === ORDER_ITEM_DESCRIPTION_TYPE &&
  items[curIndex - 1].description === "Mounted On";

/**
 * Merges items with weights if they are mounted onto a previous item with matching criteria.
 * @param {Array} items - The list of items to process.
 * @returns {Array} - Merged Items.
 */
export const mergeMountedWeight = (items) => {
  try {
    let mergedItems = [];

    items.forEach((item, index) => {
      if (isInventoryItemWithWeight(item)) {
        if (item.unitWeight > MAX_WEIGHT_OF_SHIPPING_ITEM) {
          throw new Error(
            `There is a heavy item that weigh more than ${MAX_WEIGHT_OF_SHIPPING_ITEM} pounds`,
          );
        }

        if (mergedItems.length && isMountedItem(items, index)) {
          let lastItem = mergedItems.pop();

          if (lastItem.qrd === item.qrd) {
            lastItem.unitWeight += item.unitWeight;
            lastItem.weight += item.weight;
            lastItem.item_price += item.item_price;
            lastItem.ext += item.ext;
            lastItem.wsurch += item.wsurch;

            mergedItems.push(lastItem);
          } else {
            throw new Error(
              "There are mounting mismatches in order items list",
            );
          }
        } else {
          mergedItems.push({ ...item });
        }
      }
    });

    return mergedItems;
  } catch (error) {
    throw new Error(error);
  }
};

/**
 * Creates packages with items based on certain packaging rules.
 * @param {Array} items - Items to package, each item containing information such as unit weight and quantity.
 * @returns {Array} - Combined packages, each package containing packaged items and additional information like weight, declared value and boxed.
 */
export const packageItems = (items) => {
  let packages = []; // Array to store packaged items
  let currentPackage = { ...INITIAL_PACKAGE }; // Current package being built

  // Function to add package to packages array
  const addToPackages = (pkg) => {
    packages.push(pkg);
  };

  // Function to add current package to packages array and reset current package
  const addCurrentPackageToPackages = () => {
    addToPackages(currentPackage);
    currentPackage = { ...INITIAL_PACKAGE };
  };

  // Function to create a new package with a specific quantity of an item
  const createPackage = (item, qrd) => ({
    items: [{ ...item, qrd }], // Item(s) contained in the package
    weight: +(item.unitWeight * qrd).toFixed(2), // Total weight of the package
    dv: +(item.item_price * qrd).toFixed(2), // Total declared value of the package
  });

  // Function to add a quantity of an item to the current package
  const fillCurrentPackage = (item, qrd) => {
    currentPackage = {
      items: [...currentPackage.items, { ...item, qrd }], // Add item to current package
      weight: +(currentPackage.weight + item.unitWeight * qrd).toFixed(2), // Update package weight
      dv: +(currentPackage.dv + item.item_price * qrd).toFixed(2), // Update package declared value
    };
  };

  // Function to handle packaging of items based on rules
  const handleItemPackaging = (item, packageQrd) => {
    const numsOfPackages = Math.floor((item.qrd - item.bo) / packageQrd); // Number of packages to create newly
    const numsOfLeftovers = (item.qrd - item.bo) % packageQrd; // Number of leftovers

    // Create new packages based on quantity and add them to the packages array directly
    for (let i = 0; i < numsOfPackages; i++) {
      addToPackages(createPackage(item, packageQrd));
    }

    // Handle leftover items
    if (numsOfLeftovers) {
      if (
        currentPackage.weight + item.unitWeight * numsOfLeftovers >=
        MAX_WEIGHT_OF_COMBINED_PACKAGE
      ) {
        // Current package is full, add it to packages array and reset
        addCurrentPackageToPackages();
      }

      // Fill current package with leftovers
      fillCurrentPackage(item, numsOfLeftovers);
    }
  };

  // Sort items by unit weight ascending
  items.sort((a, b) => a.unitWeight - b.unitWeight);

  // Iterate through items and package them accordingly
  items.forEach((item) => {
    if (item.unitWeight < MIN_WEIGHT_CAN_BE_COMBINED) {
      // This is normal case
      const qrdCanBeAddedToCurrentPackage = Math.floor(
        (MAX_WEIGHT_OF_COMBINED_PACKAGE - currentPackage.weight - 1) /
          item.unitWeight,
      );

      if (qrdCanBeAddedToCurrentPackage > 0) {
        // Space available in current package
        const qrdToBeAddedToCurrentPackage = Math.min(
          qrdCanBeAddedToCurrentPackage,
          item.qrd - item.bo,
        );

        // Fill current package at first in normal case
        fillCurrentPackage(item, qrdToBeAddedToCurrentPackage);

        // Remained quantity after filling current package
        const remainedQrd = item.qrd - item.bo - qrdToBeAddedToCurrentPackage;

        if (remainedQrd > 0) {
          // Current package full, add current package to packages array and continue packaging
          addCurrentPackageToPackages();
          handleItemPackaging(
            { ...item, qrd: item.qrd - qrdToBeAddedToCurrentPackage },
            Math.floor((MAX_WEIGHT_OF_COMBINED_PACKAGE - 1) / item.unitWeight),
          );
        }
      } else {
        // No space in current package, add it to packages and continue packaging
        addCurrentPackageToPackages();
        handleItemPackaging(
          item,
          Math.floor((MAX_WEIGHT_OF_COMBINED_PACKAGE - 1) / item.unitWeight),
        );
      }
    } else if (item.unitWeight * 2 < MAX_WEIGHT_OF_COMBINED_PACKAGE) {
      // This is pairing case
      handleItemPackaging(item, 2);
    } else {
      // This is single item case
      for (let i = 0; i < item.qrd - item.bo; i++) {
        addToPackages(createPackage(item, 1));
      }
    }
  });

  // If current package is not empty, add it to packages array
  if (currentPackage.weight) {
    addCurrentPackageToPackages();
  }

  // Return packages with boxed flag
  return packages.map((item) => ({
    ...item,
    boxed: item.weight < MAX_WEIGHT_OF_BOXED_PACKAGE, // Indicate if the package is boxed
  }));
};
