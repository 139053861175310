import React from "react";
import { TextField } from "@mui/material";

const InputNumber = (props) => {
  const textFieldRef = React.useRef(null);

  React.useEffect(() => {
    const handleWheel = () => document.activeElement.blur();
    textFieldRef.current?.addEventListener("wheel", handleWheel);

    return () => {
      textFieldRef.current?.removeEventListener("wheel", handleWheel);
    };
  }, []);

  return <TextField type="number" ref={textFieldRef} {...props} />;
};

export default InputNumber;
