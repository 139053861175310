import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setErrors } from "../store/slices/validations/validationsSlice";
import { resetAutoSaveTrigger } from "../store/slices/eventHandlerSlice";

const AutoSaveWrapper = ({ children }) => {
  const dispatch = useDispatch();

  const {
    autoSaveOrder: { focusOutted, shouldAutosave },
  } = useSelector((state) => state.eventHandler);

  const {
    subOrderId,
    subOrder: { placed_by, customer_po, warehouse_id },
  } = useSelector((state) => state.subOrder);

  const { customerId, customerName } = useSelector((state) => state.customer);
  const { items } = useSelector((state) => state.items);

  const { billOfLading, freightAmount, handlingCharge } = useSelector(
    (state) => state.freight,
  );

  useEffect(() => {
    if (subOrderId && shouldAutosave && focusOutted) {
      validateFields();
    } else {
      dispatch(resetAutoSaveTrigger());
    }
  }, [focusOutted]);

  /**
   * Function to analyze all the required fields in the form and
   * validate if all of them have been filled
   */
  const validateFields = () => {
    dispatch(
      setErrors({
        customer: {
          customerId,
          customerName,
        },
        orderDetails: {
          orderedBy: placed_by,
          customerPo: customer_po,
        },
        freight: {
          billOfLading,
          freightAmount,
          handlingCharge,
        },
        items,
        warehouseId: warehouse_id,
      }),
    );
  };

  return children;
};

export default AutoSaveWrapper;
