import React, { memo, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";

import { Row, Col, Button } from "react-bootstrap";

import {
  getRateLTLOptions,
  toggleFreightRaterPopupState,
  getPackageRaterOptions,
  setFreightRater,
} from "../../../store/slices/freight/freightSlice";

import FreightRaterHeader from "./FreightRaterHeader";
import LTLResults from "./LTLResults";
import PackageResults from "./PackageResults";
import LoaderSpinner from "../../atoms/LoaderSpinner";

function FreightRaterPopup() {
  // Redux
  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.loaderSpinner);
  const { open } = useSelector((state) => state.freight.freightRater);

  const applyButtonRef = useRef(null);
  const cZarZipInputRef = useRef(null);

  const [tab, setTab] = useState("ltl");
  const [focusOnDataTable, setFocusOnDataTable] = useState(false);

  const handleKeyDown = (event) => {
    if (event.shiftKey && event.key === "Enter") {
      setFocusOnDataTable(true);
    }
  };

  useEffect(() => {
    if (open) {
      cZarZipInputRef.current?.focus();
    }
  }, [open]);

  useEffect(() => {
    dispatch(
      setFreightRater({
        selectedRateLTLOption: null,
        selectedPackageRaterOption: null,
      }),
    );
  }, [dispatch, tab]);

  /**
   * Function to fetch the freight rater options
   * sending as parameter the customer id, city, route, state, street and the zip code
   */
  const fetchRaterOptions = () => {
    if (tab === "ltl") {
      dispatch(getRateLTLOptions()).then(() => {
        setFocusOnDataTable(true);
      });
    } else if (tab === "package") {
      dispatch(getPackageRaterOptions()).then(() => {
        setFocusOnDataTable(true);
      });
    }
  };

  return (
    <div onKeyDown={handleKeyDown}>
      <Modal
        show={open}
        size="xl"
        onHide={() => dispatch(toggleFreightRaterPopupState(false))}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton className="p-5">
          <Modal.Title>
            <h3 className="h5">Freight Rater</h3>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="p-5">
          <Row style={{ marginTop: "-20px" }}>
            <Col md="3">
              <Button
                variant="primary"
                className="mt-3"
                onClick={() => {
                  setTab("ltl");
                }}
              >
                Rate LTL
              </Button>
            </Col>
            <Col md="3">
              <Button
                variant="primary"
                className="mt-3"
                onClick={() => setTab("package")}
              >
                Package
              </Button>
            </Col>
            <Col md="3">
              <Button variant="primary" className="mt-3" disabled>
                Append
              </Button>
            </Col>
          </Row>

          <LoaderSpinner loading={loading} />

          {/* Freight Rater Order Details */}
          <FreightRaterHeader
            fetchRaterOptions={fetchRaterOptions}
            cZarZipInputRef={cZarZipInputRef}
          />

          {
            /* FreightRaterLTLResults */
            tab === "ltl" && (
              <LTLResults
                focusOnDataTable={focusOnDataTable}
                applyButtonRef={applyButtonRef}
                cZarZipInputRef={cZarZipInputRef}
                setFocusOnDataTable={setFocusOnDataTable}
              />
            )
          }

          {
            /* FreightRaterPackageResults */
            tab === "package" && (
              <PackageResults
                focusOnDataTable={focusOnDataTable}
                applyButtonRef={applyButtonRef}
                cZarZipInputRef={cZarZipInputRef}
                setFocusOnDataTable={setFocusOnDataTable}
              />
            )
          }
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default memo(FreightRaterPopup);
