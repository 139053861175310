import dayjs from "dayjs";
import { formatDateForDatePicker } from "../../helpers/helperFunctions.js";
import {
  PENDING_ORDER_STATUS,
  PREPAID_ORDER_HOLD_STATUS,
} from "../../helpers/const";

export const SubOrderModel = {
  customer_order_num: 0,
  customer_id: 0,
  customer: {
    customer_num: 0,
    name: "",
    parent: {},
    require_po: false,
    rental_group_num: 0,
    aftermarket_network_num: 0,
    credit_limit: "",
    prepaid_weight: 0,
    freight_line: {
      freight_line: 149,
      discount_rate: "",
      handling_charge: "",
      tarrif: "",
    },
    terms: "",
  },
  date_of_order: null,
  customer_po: "",
  customer_invoice_num: 0,
  cost: 0,
  creditcard_charge: 0,
  non_a_r_amount: 0,
  status: PENDING_ORDER_STATUS.id,
  placed_by: "",
  took_by: "tester",
  hold_status: PREPAID_ORDER_HOLD_STATUS.id,
  hold_date: formatDateForDatePicker(dayjs()),
  special_instructions: "",
  freight: {
    comments: ["", ""],
    prepaid_code: "Y",
    drop_ship_address_id: 0,
    freightAmount: 0.0,
    blind_ship: false,
    is_direct_ship: false,
    estimated_cost: 0.0,
    actual_cost: 0.0,
    handling_charge: null,
    ignore_shipping_rules: false,
    freight_line: {
      id: 149,
      title: "149 - FTS TBD",
    },
    prepaid: false,
  },
  mounted_pieces: 0,
  mounted_sales: 0,
  discount_type: "",
  is_bill_only: false,
  discount_program_id: 0,
  discount_program: {
    discount_program_num: 0,
    display_name: "",
    setup_date: formatDateForDatePicker(dayjs()),
    setup_by: "",
    internal_note: "",
    program_type: "",
  },
  discount_percent: 0,
  next_line_num: 0,
  original_took_order: "",
  warehouse_id: 0,
  warehouse_name: "TN",
  tax: 0,
  surcharge: 0,
  isUpdated: false,
  poDuplicatedSubOrders: [],
};
