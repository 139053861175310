import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import Paginator from "../Paginator/Paginator";

const VendorCategoryDiscountSchedules = ({
  total,
  data,
  programs,
  page,
  setPage,
}) => {
  return (
    <>
      <ListGroup>
        <Row className="mx-5 g-0">
          {data.map((column, colIndex) => (
            <Col key={colIndex}>
              {column.map((item, rowIndex) => (
                <ListGroup.Item
                  key={rowIndex}
                  className={`${
                    colIndex === data.length - 1 ? "" : " border-end-0"
                  }`}
                >
                  <Row>
                    <Col className="text-end fw-bold">
                      {item.vendor_category || <span>&nbsp;</span>}
                    </Col>
                    <Col>{item.discount_schedule || ""}</Col>
                  </Row>
                </ListGroup.Item>
              ))}
            </Col>
          ))}
        </Row>
      </ListGroup>
      <Row>
        <Col md="8" style={{ marginTop: "16px" }}>
          <Row>
            <Col sm="2">
              <Card.Text className="read-only-label text-end">PGMS:</Card.Text>
            </Col>
            <Col sm="8">
              <Card.Text className="read-only-info">
                {programs.map((item) => item.discount_program_num).join(", ")}
              </Card.Text>
            </Col>
          </Row>
        </Col>
        <Col md="4" className="d-flex justify-content-end">
          <Paginator
            rowsPerPage={50}
            totalOrders={total}
            page={page}
            setPage={(p) => setPage(p)}
          />
        </Col>
      </Row>
    </>
  );
};

export default VendorCategoryDiscountSchedules;
