import { useState, memo, useEffect, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { TextField } from "@mui/material";
import { Row, Col, Button } from "react-bootstrap";
import { AiOutlineSave } from "react-icons/ai";
import { useForm, Controller } from "react-hook-form";
import { formStyle } from "../../../theming/formStyle";
import DropShipTable from "./DropShipTable";
import {
  toggleDropShipPopupState,
  setDropShipInformation,
  getDropShipAddresses,
  setSelectedDropShip,
} from "../../../store/slices/freight/freightSlice.js";

function DropShipPopup({ handleAutoSaveTriggerTrue }) {
  // Refs
  const nameInputRef = useRef();
  const streetInputRef = useRef();
  const routeIputRef = useRef();
  const cityInputRef = useRef();
  const stateInputRef = useRef();
  const zipInputRef = useRef();
  const emailInputRef = useRef();
  const faxInputRef = useRef();
  const contactNameInputRef = useRef();
  const phoneInputRef = useRef();
  const createButtonRef = useRef();

  // Redux
  const dispatch = useDispatch();
  const { subOrderId } = useSelector((state) => state.subOrder);
  const {
    customerData: { shippingZipCode },
  } = useSelector((state) => state.customer);
  const {
    dropShipAddress: {
      id: dropShipAddressId,
      open,
      name,
      city,
      route,
      state,
      street,
      zipCode,
      email,
      fax,
      contactName,
      phone,
      addresses,
      total,
    },
  } = useSelector((state) => state.freight);

  // States
  const [page, setPage] = useState(0);
  const [startedEdition, setStartedEdition] = useState(false);
  const [focusOnDataTable, setFocusOnDataTable] = useState(false);

  const { control, handleSubmit, setValue } = useForm();

  /**
   * Function to fetch the drop ship addresses
   * sending as parameter the customer id, city, route, state, street and the zip code
   */
  const fetchDropShipAddresses = useCallback(
    async (page) => {
      dispatch(getDropShipAddresses({ page }));
    },
    [dispatch],
  );

  function createAddress(_data) {
    dispatch(toggleDropShipPopupState(false));
    dispatch(setDropShipInformation({ isDropShip: true }));
    handleAutoSaveTriggerTrue();
  }

  /**
   * Fetch addresses from the backend once the popup has been opened
   */
  useEffect(() => {
    if (open) {
      fetchDropShipAddresses(page);
      nameInputRef.current.focus();
    } else if (!open) {
      setPage(0);
    }
  }, [open, page]);

  /**
   * Fetch addresses from the backend once one these parameters
   * has been updated
   */
  useEffect(() => {
    let delayDebounceFn;
    if (!dropShipAddressId) {
      delayDebounceFn = setTimeout(() => {
        if (startedEdition) {
          setPage(0);
          fetchDropShipAddresses(page);
        }
      }, 500);
    }
    return () => clearTimeout(delayDebounceFn);
  }, [
    name,
    city,
    route,
    state,
    street,
    zipCode,
    startedEdition,
    dropShipAddressId,
  ]);

  useEffect(() => {
    if (dropShipAddressId && !subOrderId) {
      const selectedAddress = addresses.find(
        (dsa) => dsa.id === dropShipAddressId,
      );
      if (dropShipAddressId && selectedAddress) {
        setValue("name", selectedAddress.name);
        setValue("city", selectedAddress.city);
        setValue("route", selectedAddress.route);
        setValue("state", selectedAddress.state);
        setValue("street", selectedAddress.street);
        setValue("zipCode", selectedAddress.zipCode);
        setValue("email", selectedAddress.email);
        setValue("fax", selectedAddress.fax);
        setValue("contactName", selectedAddress.contactName);
        setValue("phone", selectedAddress.phone);
      }
    }
  }, [dropShipAddressId, subOrderId, addresses, setValue]);

  return (
    <Modal
      show={open}
      size="xl"
      onHide={() => {
        dispatch(toggleDropShipPopupState(false));
        handleAutoSaveTriggerTrue();
      }}
      centered
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton className="p-5">
        <Modal.Title>
          <h3 className="h5">Drop Ship Addresses</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-5">
        <Row>
          <Col>
            <Controller
              name="name"
              control={control}
              rules={{
                required: "This field is required",
                maxLength: {
                  value: 30,
                  message: "This field cannot have more than 30 characters",
                },
              }}
              render={({ field: { onChange }, fieldState: { error } }) => (
                <TextField
                  type="text"
                  label="Name"
                  name="name"
                  value={name}
                  style={formStyle.inputStyle}
                  InputLabelProps={formStyle.inputLabelProps}
                  disabled={dropShipAddressId ? true : false}
                  onChange={(e) => {
                    dispatch(setDropShipInformation({ name: e.target.value }));
                    setStartedEdition(true);
                    onChange(e.target.value);
                  }}
                  error={!!error}
                  helperText={error ? error.message : null}
                  inputRef={nameInputRef}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      streetInputRef.current.focus();
                    }
                  }}
                />
              )}
            />
          </Col>
          <Col>
            <Controller
              name="street"
              control={control}
              rules={{
                maxLength: {
                  value: 40,
                  message: "This field cannot have more than 40 characters",
                },
              }}
              render={({ field: { onChange }, fieldState: { error } }) => (
                <TextField
                  type="text"
                  label="Street"
                  name="street"
                  value={street}
                  style={formStyle.inputStyle}
                  InputLabelProps={formStyle.inputLabelProps}
                  disabled={dropShipAddressId ? true : false}
                  onChange={(e) => {
                    dispatch(
                      setDropShipInformation({ street: e.target.value }),
                    );
                    setStartedEdition(true);
                    onChange(e.target.value);
                  }}
                  error={!!error}
                  helperText={error ? error.message : null}
                  inputRef={streetInputRef}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      routeIputRef.current.focus();
                    }
                  }}
                />
              )}
            />
          </Col>
          <Col>
            <Controller
              name="route"
              control={control}
              rules={{
                maxLength: {
                  value: 40,
                  message: "This field cannot have more than 40 characters",
                },
              }}
              render={({ field: { onChange }, fieldState: { error } }) => (
                <TextField
                  type="text"
                  label="Street pt. 2"
                  name="route"
                  value={route}
                  style={formStyle.inputStyle}
                  InputLabelProps={formStyle.inputLabelProps}
                  disabled={dropShipAddressId ? true : false}
                  onChange={(e) => {
                    dispatch(setDropShipInformation({ route: e.target.value }));
                    setStartedEdition(true);
                    onChange(e.target.value);
                  }}
                  error={!!error}
                  helperText={error ? error.message : null}
                  inputRef={routeIputRef}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      cityInputRef.current.focus();
                    }
                  }}
                />
              )}
            />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col>
            <Controller
              name="city"
              control={control}
              rules={{
                maxLength: {
                  value: 20,
                  message: "This field cannot have more than 20 characters",
                },
              }}
              render={({ field: { onChange }, fieldState: { error } }) => (
                <TextField
                  type="text"
                  label="City"
                  name="city"
                  value={city}
                  style={formStyle.inputStyle}
                  InputLabelProps={formStyle.inputLabelProps}
                  disabled={dropShipAddressId ? true : false}
                  onChange={(e) => {
                    dispatch(setDropShipInformation({ city: e.target.value }));
                    setStartedEdition(true);
                    onChange(e.target.value);
                  }}
                  error={!!error}
                  helperText={error ? error.message : null}
                  inputRef={cityInputRef}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      stateInputRef.current.focus();
                    }
                  }}
                />
              )}
            />
          </Col>
          <Col>
            <Controller
              name="state"
              control={control}
              rules={{
                maxLength: {
                  value: 2,
                  message: "This field cannot have more than 2 characters",
                },
              }}
              render={({ field: { onChange }, fieldState: { error } }) => (
                <TextField
                  type="text"
                  label="State"
                  name="state"
                  value={state}
                  style={formStyle.inputStyle}
                  InputLabelProps={formStyle.inputLabelProps}
                  disabled={dropShipAddressId ? true : false}
                  onChange={(e) => {
                    dispatch(setDropShipInformation({ state: e.target.value }));
                    setStartedEdition(true);
                    onChange(e.target.value);
                  }}
                  error={!!error}
                  helperText={error ? error.message : null}
                  inputRef={stateInputRef}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      zipInputRef.current.focus();
                    }
                  }}
                />
              )}
            />
          </Col>
          <Col>
            <Controller
              name="zipCode"
              control={control}
              rules={{
                maxLength: {
                  value: 10,
                  message: "This field cannot have more than 10 characters",
                },
              }}
              render={({ field: { onChange }, fieldState: { error } }) => (
                <TextField
                  type="text"
                  label="Zip Code"
                  name="zipCode"
                  value={zipCode}
                  style={formStyle.inputStyle}
                  InputLabelProps={formStyle.inputLabelProps}
                  disabled={dropShipAddressId ? true : false}
                  onChange={(e) => {
                    dispatch(
                      setDropShipInformation({ zipCode: e.target.value }),
                    );
                    setStartedEdition(true);
                    onChange(e.target.value);
                  }}
                  error={!!error}
                  helperText={error ? error.message : null}
                  inputRef={zipInputRef}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      emailInputRef.current.focus();
                    }
                  }}
                />
              )}
            />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col>
            <Controller
              name="email"
              control={control}
              rules={{
                maxLength: {
                  value: 60,
                  message: "This field cannot have more than 60 characters",
                },
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: "Entered value does not match email format",
                },
              }}
              render={({ field: { onChange }, fieldState: { error } }) => (
                <TextField
                  type="text"
                  label="Email"
                  name="email"
                  value={email}
                  style={formStyle.inputStyle}
                  InputLabelProps={formStyle.inputLabelProps}
                  disabled={dropShipAddressId ? true : false}
                  onChange={(e) => {
                    dispatch(setDropShipInformation({ email: e.target.value }));
                    onChange(e.target.value);
                  }}
                  error={!!error}
                  helperText={error ? error.message : null}
                  inputRef={emailInputRef}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      faxInputRef.current.focus();
                    }
                  }}
                  //getFocus={}
                />
              )}
            />
          </Col>
          <Col>
            <Controller
              name="fax"
              control={control}
              rules={{
                maxLength: {
                  value: 14,
                  message: "This field cannot have more than 14 characters",
                },
              }}
              render={({ field: { onChange }, fieldState: { error } }) => (
                <TextField
                  type="text"
                  label="Fax"
                  name="fax"
                  value={fax}
                  style={formStyle.inputStyle}
                  InputLabelProps={formStyle.inputLabelProps}
                  disabled={dropShipAddressId ? true : false}
                  onChange={(e) => {
                    dispatch(setDropShipInformation({ fax: e.target.value }));
                    onChange(e.target.value);
                  }}
                  error={!!error}
                  helperText={error ? error.message : null}
                  inputRef={faxInputRef}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      contactNameInputRef.current.focus();
                    }
                  }}
                />
              )}
            />
          </Col>
          <Col>
            <Controller
              name="contactName"
              control={control}
              rules={{
                maxLength: {
                  value: 20,
                  message: "This field cannot have more than 20 characters",
                },
              }}
              render={({ field: { onChange }, fieldState: { error } }) => (
                <TextField
                  type="text"
                  label="Contact Name"
                  name="contactName"
                  value={contactName}
                  style={formStyle.inputStyle}
                  InputLabelProps={formStyle.inputLabelProps}
                  disabled={dropShipAddressId ? true : false}
                  onChange={(e) => {
                    dispatch(
                      setDropShipInformation({ contactName: e.target.value }),
                    );
                    onChange(e.target.value);
                  }}
                  error={!!error}
                  helperText={error ? error.message : null}
                  inputRef={contactNameInputRef}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      phoneInputRef.current.focus();
                    }
                  }}
                />
              )}
            />
          </Col>
          <Col>
            <Controller
              name="phone"
              control={control}
              rules={{
                maxLength: {
                  value: 14,
                  message: "This field cannot have more than 14 characters",
                },
              }}
              render={({ field: { onChange }, fieldState: { error } }) => (
                <TextField
                  type="text"
                  label="Phone"
                  name="phone"
                  value={phone}
                  style={formStyle.inputStyle}
                  InputLabelProps={formStyle.inputLabelProps}
                  disabled={dropShipAddressId ? true : false}
                  onChange={(e) => {
                    dispatch(setDropShipInformation({ phone: e.target.value }));
                    onChange(e.target.value);
                  }}
                  error={!!error}
                  helperText={error ? error.message : null}
                  inputRef={phoneInputRef}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      setFocusOnDataTable(true);
                    }
                  }}
                  onBlur={() => setFocusOnDataTable(false)}
                />
              )}
            />
          </Col>
        </Row>
        <DropShipTable
          selectedAddress={dropShipAddressId}
          setSelectedAddress={useCallback(
            (addressId) => {
              if (addressId === 0) {
                setPage(0);
              }
              dispatch(setSelectedDropShip({ addressId, shippingZipCode }));
            },
            [dispatch, shippingZipCode],
          )}
          totalAddresses={total}
          dropShipAddresses={addresses}
          focusOnDataTable={focusOnDataTable}
          nextFocusRef={createButtonRef}
          page={page}
          setPage={(p) => setPage(p)}
        />
        <div
          className="d-flex justify-content-end align-items-center"
          style={{ marginTop: "-36px" }}
        >
          <div className="drop-ship-popup__guide">
            {dropShipAddressId
              ? "You have selected  one of the addresses of the list above"
              : "You will create a new address with the data filled in the form above"}
          </div>
          {dropShipAddressId ? (
            <Button
              variant="primary"
              className="ms-5"
              ref={createButtonRef}
              onClick={() => {
                dispatch(toggleDropShipPopupState(false));
                handleAutoSaveTriggerTrue();
              }}
            >
              <span className="me-2">Select</span>
              <AiOutlineSave />
            </Button>
          ) : (
            <Button
              variant="primary"
              className="ms-5"
              ref={createButtonRef}
              onClick={handleSubmit(createAddress)}
            >
              <span className="me-2">Create</span>
              <AiOutlineSave />
            </Button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default memo(DropShipPopup);
