export const formatFaxEmailData = (data) => {
  const clonedData = JSON.parse(JSON.stringify(data)); // deep clone

  const formattedData = {
    ...clonedData,
    bill_back_account: clonedData.billBackAccount
      ? clonedData.billBackAccount
      : "",
    use_blank_coversheet: clonedData.useBlankCoversheet || false,
    confirmation_email: clonedData.confirmationEmail
      ? clonedData.confirmationEmail
      : "",
    delivery_datetime: null,
    recipients: clonedData.recipients
      .filter((r) => r !== null)
      .map((r) => {
        if (!r.email) delete r.email;
        if (!r.fax) delete r.fax;
        if (!r.name) delete r.name;
        return r;
      }),
  };

  delete formattedData.confirmationEmail;
  delete formattedData.billBackAccount;
  delete formattedData.useBlankCoversheet;

  return formattedData;
};
