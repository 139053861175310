import React, { memo, useState } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

function FaxEmailDropdown({ options, addRecipient }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleAddSelected = (event) => {
    const index = event.target.value;
    addRecipient({
      email: options[index]?.email,
      fax: options[index]?.fax,
      name: options[index]?.name,
    });
    handleClose();
  };

  const handleAddEmpty = () => {
    addRecipient();
    handleClose();
  };

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        text
        sx={{ textTransform: "none" }}
        onClick={handleClick}
        endIcon={anchorEl ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      >
        Add New Recipient
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleAddEmpty}>Add Empty Recipient</MenuItem>
        {options?.map((option, index) => (
          <MenuItem
            key={index}
            value={index}
            onClick={handleAddSelected}
          >{`${option.email} | ${option.fax} | ${option.name}`}</MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default memo(FaxEmailDropdown);
