import { memo } from "react";
import Spinner from "react-bootstrap/Spinner";

function LoaderSpinner({ loading }) {
  return (
    <>
      {loading && (
        <>
          <div
            className="fixed-top bg-dark"
            style={{ "--bs-bg-opacity": ".5", height: "100vh", zIndex: 5000 }}
          />

          <div
            className="fixed-top d-flex justify-content-center align-items-center"
            style={{ height: "100vh", zIndex: 5000 }}
          >
            <Spinner animation="grow" variant="success" />
          </div>
        </>
      )}
    </>
  );
}

export default memo(LoaderSpinner);
