/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Heading from "../Heading/Heading";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import { getOrderStatusLabel } from "../../../helpers/helperFunctions";
import { getCustomerRecentOrders } from "../../../store/slices/customer/customerSlice";

const CustomerOrders = () => {
  const [customerOrders, setCustomerOrders] = useState([{}]);
  const [loading, setLoading] = useState(false);

  // Redux
  const { customerId } = useSelector((state) => state.customer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (customerId) {
      setLoading(true);
      dispatch(getCustomerRecentOrders({ id: customerId })).then((res) => {
        if (!res.error) {
          setCustomerOrders(res.payload);
          setLoading(false);
        }
      });
    }
  }, [customerId]);

  return (
    <div className="mt-5">
      <Heading title="Customer Orders" />
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ width: "100%", minHeight: 200 }}
        >
          <Spinner animation="grow" variant="primary" />
        </div>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Order Number</th>
              <th>BOL</th>
              <th>Date Of Order</th>
              <th>Status</th>
              <th>Total</th>
              <th>Agent</th>
              <th>Warehouse</th>
              <th>Comments</th>
            </tr>
          </thead>
          <tbody>
            {customerOrders?.length > 0 &&
              customerOrders.map((order, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <Link to={`/sub-order/${order.customer_order_num}`}>
                        {order.customer_order_num}
                      </Link>
                    </td>
                    <td>{order?.freight?.bill_of_lading}</td>
                    <td>{order.date_of_order}</td>
                    <td>{getOrderStatusLabel(order.status)}</td>
                    <td>{order.total}</td>
                    <td>{order.took_by}</td>
                    <td>{order.warehouse_name}</td>
                    <td>
                      {order?.comments?.length > 0
                        ? order.comments.join(" ")
                        : ""}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default CustomerOrders;
