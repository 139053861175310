import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import Heading from "../../components/sections/Heading/Heading";
import { Link } from "react-router-dom";
import DataGrid from "../../components/atoms/Table/DataGrid";
import { getQuoteListByParameters } from "../../store/slices/quoteSlice";
import LoaderSpinner from "../../components/atoms/LoaderSpinner";

const headerAttributes = [
  {
    id: "quoteNumber",
    label: "Quote #",
    tooltip: "Quote Number",
    sortable: true,
    searchable: {
      label: "Search by Quote Number",
      type: "text",
      width: 2,
    },
    width: "5%",
  },
  {
    id: "po",
    label: "PO",
    sortable: false,
  },
  {
    id: "dateOfQuote",
    label: "Date",
    tooltip: "Date of Quote",
    sortable: true,
    width: "10%",
  },
  {
    id: "total",
    label: "Total",
    sortable: true,
  },
  {
    id: "agent",
    label: "Agent",
    sortable: true,
  },
  {
    id: "warehouseName",
    label: "Wh",
    tooltip: "Warehouse Name",
    sortable: true,
    width: "5%",
  },
  {
    id: "customerNum",
    label: "",
    sortable: true,
    searchable: {
      label: "Search by Customer Number",
      type: "text",
      width: 2,
    },
    hidden: true,
  },
  {
    id: "customer",
    label: "Customer",
    sortable: true,
    searchable: {
      label: "Search by Customer Name",
      type: "text",
      width: 2,
    },
  },
  {
    id: "dropShipAddress",
    label: "Drop Ship Address",
    sortable: false,
  },
  {
    id: "comments",
    label: "Comments",
    sortable: false,
  },
  {
    id: "view",
    label: "Details",
    sortable: false,
  },
];

const Quotes = () => {
  const [dataList, setDataList] = useState([]);
  const [totalQuotes, setTotalQuotes] = useState(0);

  // Redux
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.loaderSpinner);

  const returnRealAttributeName = (attr) => {
    switch (attr) {
      case "quoteNumber":
        return "quote_num";
      case "dateOfQuote":
        return "date_of_quote";
      case "customer":
        return "customer_name";
      case "warehouseName":
        return "warehouse_id";
      default:
        return attr;
    }
  };

  const fetchQuotes = useCallback(
    async ({
      orderBy,
      sortOrder,
      page,
      quoteSearchId,
      custNum,
      custName,
      from,
      to,
    }) => {
      dispatch(
        getQuoteListByParameters({
          quoteId: quoteSearchId,
          page,
          customerNum: custNum,
          customerName: custName,
          orderBy: returnRealAttributeName(orderBy),
          sortOrder: sortOrder === "desc" ? "dsc" : "asc",
          from,
          to,
        }),
      ).then((res) => {
        if (!res?.error) {
          setTotalQuotes(res.payload.count);

          if (res?.payload.results) {
            setDataList(
              res.payload.results.map((d) => ({
                id: d.quote_num,
                quoteNumber: d.quote_num,
                po: d?.customer_po,
                dateOfQuote: d.date_of_quote,
                total: d.total,
                agent: d.took_by,
                warehouseName: d.warehouse_name,
                customer: d.customer?.name ? (
                  <Link to={`/customers/${d.customer_id}`}>
                    {d.customer.name} | {d.customer_id}
                  </Link>
                ) : (
                  <span></span>
                ),
                dropShipAddress: d?.freight?.drop_ship_address
                  ?.drop_ship_address_id
                  ? d.freight.drop_ship_address.address.city +
                    d.freight.drop_ship_address.address.state
                  : "N/A",
                comments:
                  d?.freight?.comments?.length > 0
                    ? d.freight.comments.join(" ")
                    : "",
                actions: <Link to={`/quote/${d.quote_num}`}>View</Link>,
              })),
            );
          }
        }
      });
    },
    [],
  );

  const prepareFetchQuotes = useCallback(
    async ({ page, orderBy, sortOrder, customParameters }) => {
      let quoteSearchId = "";
      let customerNum = "";
      let customerName = "";
      let from = null;
      let to = null;

      if (customParameters) {
        customParameters.forEach((c) => {
          if (c?.id === "quoteNumber") {
            quoteSearchId = c.value;
          }

          if (c?.id === "customerNum") {
            customerNum = c.value;
          }

          if (c?.id === "customer") {
            customerName = c.value;
          }

          if (c?.id === "dates") {
            from = dayjs(c.value[0]).format("YYYY-MM-DD");
            to = dayjs(c.value[1]).format("YYYY-MM-DD");
          }
        });
      }

      await fetchQuotes({
        page,
        orderBy,
        sortOrder,
        quoteSearchId,
        custNum: customerNum,
        custName: customerName,
        from,
        to,
      });
    },
    [fetchQuotes],
  );

  return (
    <div>
      <Heading title="Quotes" />
      <div className="mt-5">
        <div className="mt-5">
          <DataGrid
            headerAttributes={headerAttributes}
            dataList={dataList}
            hideId={true}
            rowsPerPage={50}
            total={totalQuotes}
            defaultOrder="desc"
            defaultOrderBy="quoteNumber"
            fetchData={prepareFetchQuotes}
            dateFilter={{
              show: true,
              inputs: [
                {
                  label: "From",
                  name: "from",
                },
                {
                  label: "To",
                  name: "to",
                },
              ],
            }}
          />
        </div>
      </div>
      <LoaderSpinner loading={loading} />
    </div>
  );
};

export default Quotes;
