import { createSlice } from "@reduxjs/toolkit";
import { CUSTOMER_SECTION_REF, CUSTOMER_ID_REF } from "../../helpers/const";

export const refsSlice = createSlice({
  name: "refsSlice",
  initialState: {
    fieldRef: CUSTOMER_ID_REF,
    sectionRef: CUSTOMER_SECTION_REF,
  },
  reducers: {
    setSectionRef: (state, action) => {
      state.sectionRef = action.payload;
    },
    setFieldRef: (state, action) => {
      state.fieldRef = action.payload;
    },
  },
});

export const { setSectionRef, setFieldRef } = refsSlice.actions;
export default refsSlice.reducer;
