import { BsFillExclamationCircleFill } from "react-icons/bs";

function ErrorIcon() {
  return (
    <div className="d-flex justify-content-center mt-3 color-danger">
      <BsFillExclamationCircleFill style={{ fill: "#d32f2f" }} />
    </div>
  );
}

export default ErrorIcon;
