export const getErrorMessage = (axiosData) => {
  if (axiosData?.axiosError?.request?.status !== 401) {
    if (axiosData?.customMsg) {
      return axiosData?.customMsg;
    }
    return axiosData?.axiosError?.message;
  } else {
    return "Your session has expired. Login again";
  }
};
