import { useCallback, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import {
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

import { formStyle } from "../../../theming/formStyle";
import { validateNumber } from "../../../helpers/helperFunctions.js";
import Table from "../../atoms/Table/Table.js";
import { useDispatch, useSelector } from "react-redux";
import {
  getFreightLinesByScacCode,
  setFreight,
  setFreightRater,
  setSelectedRateLTLOption,
  toggleFreightRaterPopupState,
} from "../../../store/slices/freight/freightSlice.js";
import { PREPAID_OPTIONS } from "../../../helpers/const.js";
import { setAutoSaveFocusOuttedTrue } from "../../../store/slices/eventHandlerSlice";

export default function LTLResults({
  focusOnDataTable,
  applyButtonRef,
  cZarZipInputRef,
  setFocusOnDataTable,
}) {
  const dispatch = useDispatch();

  const { subOrderId } = useSelector((state) => state.subOrder);

  const {
    weight,
    prepaid,
    thirdPartyBilling,
    freightAmount: freightAmountStore,
    freightRater: {
      czarZip,
      destinationZip,
      testCzarZip,
      testDestinationZip,
      rateLTLOptions,
      selectedRateLTLOption,
    },
  } = useSelector((state) => state.freight);

  const [freightAmount, setFreightAmount] = useState(freightAmountStore);
  const [prepaidOption, setPrepaidOption] = useState(
    prepaid
      ? +freightAmountStore
        ? PREPAID_OPTIONS.PREPAID_AND_ADD
        : PREPAID_OPTIONS.PREPAID
      : PREPAID_OPTIONS.COLLECT,
  );

  const setFreightRaterInfo = useCallback((id, data) => {
    dispatch(setSelectedRateLTLOption(id ? data : null));
    setFocusOnDataTable(true);
  }, []);

  const handlePrepaidOptionChange = (option) => {
    if (option === PREPAID_OPTIONS.PREPAID) {
      setFreightAmount(0);
    }
    setPrepaidOption(option);
  };

  const handleFreightAmountProccess = (freightAmountValue) => {
    const validaNumber = validateNumber(freightAmountValue, 2);

    if (validaNumber) {
      let _prepaidOption = PREPAID_OPTIONS.COLLECT;

      if (prepaidOption !== PREPAID_OPTIONS.COLLECT) {
        if (freightAmountValue) {
          _prepaidOption = PREPAID_OPTIONS.PREPAID_AND_ADD;
        } else {
          _prepaidOption = PREPAID_OPTIONS.PREPAID;
        }
      }

      setFreightAmount(
        _prepaidOption === PREPAID_OPTIONS.PREPAID ? 0 : freightAmountValue,
      );
      setPrepaidOption(_prepaidOption);
    }
  };

  /**
   * Function to apply the freight data to the order object in Redux
   */
  const applyData = () => {
    let prepaidCode = "";
    let prepaidBool = false;

    if (
      prepaidOption === PREPAID_OPTIONS.PREPAID ||
      prepaidOption === PREPAID_OPTIONS.PREPAID_AND_ADD
    ) {
      prepaidCode = thirdPartyBilling.name ? "3" : "Y";
      prepaidBool = true;
    } else {
      prepaidCode = "N";
      prepaidBool = false;
    }

    dispatch(
      getFreightLinesByScacCode({ carrier: selectedRateLTLOption?.carrier }),
    ).then(() => {
      if (subOrderId) {
        dispatch(setAutoSaveFocusOuttedTrue());
      }
    });

    dispatch(
      setFreight({
        freightAmount,
        prepaidCode,
        prepaid: prepaidBool,
        estimatedCost: selectedRateLTLOption?.net,
        handlingCharge: 0,
      }),
    );

    dispatch(
      setFreightRater({
        carrierQuoteNumber: selectedRateLTLOption?.carrierQuoteNumber,
        TMSQuoteNumber: selectedRateLTLOption?.TMSQuoteNumber,
        rated: true,
        ratedWeight: weight,
        selectedRateLTLOption: null,
      }),
    );

    dispatch(toggleFreightRaterPopupState(false));
  };

  const onCancel = () => {
    dispatch(toggleFreightRaterPopupState(false));
  };

  return (
    <>
      <Row>
        <Col md="12">
          {rateLTLOptions.length === 0 && (
            <p className="mt-5 p-3 bg-warning text-center">
              No rated results. It can take multiple tries to get options.
            </p>
          )}
          {weight === 0 && (
            <p className="mt-5 p-3 bg-danger text-white text-center">
              Order needs to have weight to be rated
            </p>
          )}
          {!destinationZip && (
            <p className="mt-5 p-3 bg-danger text-white text-center">
              Set a customer and/or drop ship address to be rated
            </p>
          )}
          <div className="drop-ship-popup__table mt-5">
            <Table
              selector="radio"
              headerAttributes={[
                {
                  id: "id",
                  label: "Select",
                },
                {
                  id: "name",
                  label: "Name",
                },
                {
                  id: "net",
                  label: "Net",
                  sortable: true,
                  type: "number",
                },
                {
                  id: "days",
                  label: "Days",
                  sortable: true,
                  type: "number",
                },
                {
                  id: "carrier",
                  label: "Carrier",
                },
                {
                  id: "carrierQuoteNumber",
                  label: "CarrierQuoteNumber",
                },
                {
                  id: "TMSQuoteNumber",
                  label: "TMSQuoteNumber",
                },
              ]}
              dataList={rateLTLOptions}
              hideId={true}
              onSelectRow={setFreightRaterInfo}
              selectedItem={selectedRateLTLOption?.id}
              getFocus={focusOnDataTable}
              nextFocusRef={applyButtonRef}
              prevFocusRef={cZarZipInputRef}
              setFocusOnDataTable={setFocusOnDataTable}
            />
          </div>
        </Col>
      </Row>

      {selectedRateLTLOption && (
        <Row className="mt-5">
          <Row className="mt5">
            <Col md="4">
              <p>Prepaid freight amount: {freightAmount} </p>
              <p>Net cost: {selectedRateLTLOption?.net}</p>
            </Col>
            <Col md="4" className="text-center">
              <p>
                Selected: {selectedRateLTLOption?.carrier} -{" "}
                {selectedRateLTLOption?.name}
              </p>
              <p>Delivery ETA: {selectedRateLTLOption?.days} days from now</p>
            </Col>
            <Col md="4" className="text-end">
              <p>CQN: {selectedRateLTLOption?.carrierQuoteNumber}</p>
              <p>TMSQN: {selectedRateLTLOption?.TMSQuoteNumber}</p>
            </Col>
          </Row>
          <Row className="mt5">
            <Col md="4">
              <FormControl>
                <RadioGroup
                  aria-labelledby="radio-buttons-group-label"
                  className="mt-3"
                  value={prepaidOption}
                  name="prepaidOption"
                  onChange={(e) => handlePrepaidOptionChange(e.target.value)}
                >
                  <FormControlLabel
                    value={PREPAID_OPTIONS.PREPAID}
                    control={<Radio />}
                    label="Prepaid"
                  />
                  <FormControlLabel
                    value={PREPAID_OPTIONS.PREPAID_AND_ADD}
                    control={<Radio />}
                    label="Prepaid and Add"
                  />
                  <FormControlLabel
                    value={PREPAID_OPTIONS.COLLECT}
                    control={<Radio />}
                    label="Collect"
                  />
                </RadioGroup>
              </FormControl>
            </Col>
            <Col md="4">
              <TextField
                className="mt-3"
                label="Freight Amount"
                style={formStyle.inputStyle}
                InputLabelProps={formStyle.inputLabelProps}
                value={freightAmount}
                onChange={(e) => setFreightAmount(+e.target.value)}
                onBlur={(e) => handleFreightAmountProccess(+e.target.value)}
              />
            </Col>
            <Col md="4" className="d-flex justify-content-end">
              <Button
                variant="primary"
                className="mt-3 mx-3"
                style={{ maxHeight: 48 }}
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button
                variant="secondary"
                className="mt-3"
                style={{ maxHeight: 48 }}
                ref={applyButtonRef}
                onClick={(e) => {
                  if (!e.shiftKey) {
                    applyData();
                  }
                }}
                disabled={
                  czarZip !== testCzarZip ||
                  destinationZip !== testDestinationZip
                }
              >
                Apply
              </Button>
            </Col>
          </Row>
        </Row>
      )}
    </>
  );
}
