import {
  AR_BALANCE_MEDIUM_STATUS,
  AR_BALANCE_BAD_STATUS,
  PENDING_ORDER_STATUS,
  APPROVED_ORDER_STATUS,
  CREDIT_ORDER_HOLD_STATUS,
  IN_PROGRESS_ORDER_STATUS,
} from "../../helpers/const";

export function setSubOrderStatus(subOrder, creditStatus, isDirectShip) {
  const badStatus = [AR_BALANCE_MEDIUM_STATUS, AR_BALANCE_BAD_STATUS].includes(
    creditStatus,
  );

  if (subOrder.is_bill_only && subOrder.status === APPROVED_ORDER_STATUS.id) {
    return {
      status: IN_PROGRESS_ORDER_STATUS.id,
    };
  }

  if (badStatus && subOrder.status === APPROVED_ORDER_STATUS.id) {
    return {
      status: PENDING_ORDER_STATUS.id,
      hold_status: CREDIT_ORDER_HOLD_STATUS.id,
    };
  }

  if (isDirectShip && subOrder.status === APPROVED_ORDER_STATUS.id) {
    return {
      status: IN_PROGRESS_ORDER_STATUS.id,
    };
  }

  return {
    status: subOrder.status,
  };
}
