import React, { memo, useState } from "react";
import { AiOutlineMail } from "react-icons/ai";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import FaxEmailForm from "./FaxEmailForm";

function FaxEmail() {
  const [openPopup, setOpenPopup] = useState(false);

  return (
    <div>
      <Button variant="primary me-2" onClick={() => setOpenPopup(true)}>
        <span className="me-2">Fax/Email</span>
        <AiOutlineMail />
      </Button>
      <Modal
        show={openPopup}
        centered
        aria-labelledby="contained-modal-title-vcenter"
        onHide={() => setOpenPopup(false)}
        size="lg"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="p-5" closeButton>
          <Modal.Title>
            <h3 className="h5">Email/Fax Order Details</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-5">
          <FaxEmailForm closePopup={() => setOpenPopup(false)} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default memo(FaxEmail);
