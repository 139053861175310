import { useState, useEffect } from "react";
import Navigation from "../Navigation/Navigation";
import SubHeading from "../SubHeading/SubHeading";
import { useLocation } from "react-router-dom";

function Header() {
  const location = useLocation();
  const [showHeader, setShowHeader] = useState(false);

  useEffect(() => {
    if (
      location.pathname === "/" ||
      location.pathname.includes("/sub-order/") ||
      location.pathname.includes("/quote/") ||
      location.pathname.includes("/new-order/")
    ) {
      setShowHeader(true);
    } else {
      setShowHeader(false);
    }
  }, [location.pathname]);

  return (
    <header className="sticky-top bg-light-white">
      <Navigation />
      {showHeader && <SubHeading />}
    </header>
  );
}

export default Header;
