import React from "react";
import { useDispatch } from "react-redux";
import { TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { formStyle } from "../../../theming/formStyle.js";
import { formatDateForDatePicker } from "../../../helpers/helperFunctions.js";
import { setHoldDate } from "../../../store/slices/subOrderSlice.js";

const HoldDate = ({ holdDate }) => {
  // Redux
  const dispatch = useDispatch();

  return (
    <div className={`HoldDate`}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          label="Hold Date"
          className="muiDatePicker"
          inputFormat="MM/DD/YYYY"
          name="hold_date"
          value={dayjs(holdDate)}
          onChange={(e) => {
            if (e !== null) {
              if (!isNaN(e.$D)) {
                dispatch(setHoldDate(formatDateForDatePicker(e)));
              } else {
                dispatch(setHoldDate(""));
              }
            } else {
              dispatch(setHoldDate(""));
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              style={formStyle.inputStyle}
              //error={errors.holdDateTouched ? true : false}
              //helperText={errors.holdDateTouched}
              InputLabelProps={formStyle.inputLabelProps}
            />
          )}
        />
      </LocalizationProvider>
    </div>
  );
};

export default HoldDate;
