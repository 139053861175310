import { TablePagination } from "@mui/material";
import "./Paginator.scss";

function Paginator({ rowsPerPage, totalOrders, page, setPage }) {
  return (
    <table>
      <tbody>
        <tr>
          <TablePagination
            className="paginator"
            rowsPerPageOptions={[]}
            rowsPerPage={rowsPerPage}
            count={totalOrders}
            page={page}
            onPageChange={(e, p) => setPage(p)}
          />
        </tr>
      </tbody>
    </table>
  );
}

export default Paginator;
