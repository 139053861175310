import React, { useReducer } from "react";
// import 'bootstrap/dist/css/bootstrap.min.css';
import Alert from "react-bootstrap/Alert";
import "./AlertMessage.scss";

export const AlertMessageContext = React.createContext();

const reducer = (list, action) => {
  switch (action.type) {
    case "add":
      if (list.every((x) => x.id !== action.alert.id)) {
        return [...list, action.alert];
      } else {
        return list;
      }
    case "remove":
      const newList = list.filter((a) => a.id !== action.alertId);
      return newList;
    default:
      throw new Error("what's going on?");
  }
};

export const AlertMessage = ({ children }) => {
  const [list, dispatch] = useReducer(reducer, []);

  const handleOpen = (id, type, message, timedOut) => {
    dispatch({ type: "add", alert: { id, type, message } });
    if (timedOut) {
      setTimeout(() => {
        dispatch({ type: "remove", alertId: id });
      }, [timedOut]);
    }
  };

  const handleClose = (alertId) => {
    dispatch({ type: "remove", alertId });
  };

  return (
    <AlertMessageContext.Provider value={{ handleOpen }}>
      {children}
      <div className={list.length > 0 ? "alert-container" : ""}>
        {list.map((alert) => (
          <Alert
            key={alert.id}
            transition={true}
            variant={alert.type}
            dismissible
            onClose={() => handleClose(alert.id)}
          >
            {alert.message}
          </Alert>
        ))}
      </div>
    </AlertMessageContext.Provider>
  );
};
