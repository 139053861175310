import { BASE_API_URL } from "./baseUrl";
// Base URL for the API
export const endpoints = {
  TESTAPI_URL: "https://jsonplaceholder.typicode.com/posts",
  MITCO_API_URL: BASE_API_URL,

  // Authentication Endpoints
  USER_LOGIN: "/users/login/",
  USER_LOGOUT: "/users/logout/",

  // Endpoints for the API
  CUSTOMER_LIST: "/client/", // add customer id to end of endpoint to get specific customer
  CUSTOMER_CREDITS_LIST: "/client/credits/",
  CUSTOMER_NETPRICE_LIST: "/client/netprice/",
  CUSTOMER_ORDERS_LIST: "/client/orders/",
  TRACKS_LIST: "/tracks/",
  VC: "/vc/",
  WAREHOUSE_LIST: "/warehouse/", // add warehouse id to end of endpoint to get specific warehouse
  CSA_ORDER_READ: "/csa/order/", // add id
  SUB_ORDER_READ: "/suborder/", // add id
  SUB_ORDER_POST: "/suborder/",
  QUOTE_POST: "/suborder/as_quote/",
  SUBORDER_ITEMS_CREATE: "/items/",
  SUBORDER_ITEMS_DELETE: "/items/",
  CSA_PRODUCT: "/csa/product/",
  CSA_PRODUCT_PRICE: "/price/",
  ITEMS_READ: "/items/", // add { product_code } to end of endpoint to get specific item
  WAREHOUSE_READ: "/csa/warehouse/", // add warehouse {id} to end of endpoint to get specific warehouse
  CUSTOMER_READ: "/client/", // add {customer_num} to end of endpoint to get specific customer
  FREIGHT_LINE: "/freight_line/",

  // Variations for specific customer info (e.g. /client/1/account_balance/)
  CUSTOMER_ACCOUNT_BALANCE: "/account_balance/",
  CUSTOMER_CHECK_CREDIT: "/check_credit/",
  CUSTOMER_VENDOR_DISCOUNTS: "/vendor_discounts/",
  RECENT_ORDERS: "/recent_orders/",

  // Vendor Category Discounts
  VENDOR_CATEGORY_DISCOUNTS: "/vendor_category_discounts/",
  DISCOUNT_SCHEDULES_FOR_PRODUCTS:
    "/vendor_category_discounts/discount_schedules/",

  DROP_SHIP_ADDRESSES: "/client/{customer}/drop_ship_addresses/",
  GET_QUOTE: "/quote/{quote_num}/",
  SAVE_AS_SUBORDER: "/quote/{quote_num}/to_suborder/",
  DELETE_SUB_ORDER: "/suborder/",
  RATE_LTL: "/rateLTL/",

  XREF_PRODUCTS: "/xref_products/",
};
