import { memo, useState, useRef, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { TextField, debounce } from "@mui/material";
import { Row, Col, Button } from "react-bootstrap";
import { AiOutlineSave } from "react-icons/ai";
import { formStyle } from "../../../theming/formStyle";
import XRefTable from "./XRefTable";
import { getXrefProducts } from "../../../store/slices/customer/customerSlice";

function XRefPopup({ open, setOpen, addItem }) {
  // Refs
  const codeInputRef = useRef();

  const dispatch = useDispatch();

  const { customerId, xrefProducts } = useSelector((state) => state.customer);

  const [selectedItem, setSelectedItem] = useState();
  const [xrefCode, setXrefCode] = useState();
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (customerId) {
      const debouncing = setTimeout(() => {
        dispatch(getXrefProducts({ customerId, code: xrefCode }));
      }, 500);
      return () => clearTimeout(debouncing);
    }
  }, [customerId, xrefCode]);

  const handlePageChange = useCallback(
    (newPage) => {
      dispatch(
        getXrefProducts({
          customerId,
          xrefCode,
          page: newPage + 1,
        }),
      );

      setPage(newPage);
    },
    [customerId, xrefCode, setPage],
  );

  const onSelectedIdChanged = (id, data) => {
    setSelectedItem(data);
  };

  const onApply = () => {
    setOpen(false);
    addItem({
      productCode: selectedItem?.id,
      referenceCode: selectedItem?.referenceCode,
    });
  };

  return (
    <Modal
      show={open}
      aria-labelledby="contained-modal-title-vcenter"
      size="xl"
      centered
      onHide={() => setOpen(false)}
    >
      <Modal.Header closeButton className="pt-4 px-5">
        <Modal.Title>
          <h3 className="h5">Product Search by XCrossRef code</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-5">
        <Row>
          <Col>
            <TextField
              type="text"
              label="XCrossRef Code"
              name="xrefcode"
              value={xrefCode}
              style={formStyle.inputStyle}
              InputLabelProps={formStyle.inputLabelProps}
              onChange={(e) => {
                setXrefCode(e.target.value);
              }}
              inputRef={codeInputRef}
            />
          </Col>
        </Row>
        <XRefTable
          selectedId={selectedItem?.id}
          setSelectedId={onSelectedIdChanged}
          total={xrefProducts.total}
          products={xrefProducts.data}
          page={page}
          setPage={handlePageChange}
        />
        <div
          className="d-flex justify-content-end align-items-center"
          style={{ marginTop: "-36px" }}
        >
          <div className="advanced-customer-search-popup__guide">
            {selectedItem?.id
              ? "You have selected one of the customer of the list above"
              : "You can select one of the customer of the list above"}
          </div>

          <Button
            variant="primary"
            className="ms-5"
            disabled={!selectedItem?.id}
            onClick={onApply}
          >
            <span className="me-2">Apply</span>
            <AiOutlineSave />
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default memo(XRefPopup);
