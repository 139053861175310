// HARD CODED VALUES BASED ON MOST RECENT ORDERS
export const AvailableProductCodes = [
  "04320001",
  "04400001",
  "04400102",
  "04420001",
  "04485001",
  "04485101",
  "04490001",
  "04570101",
  "04610001",
  "04610102",
  "04645001",
  "04650101",
  "04650102",
  "04665001",
  "06130001",
  "06400301",
  "06405001",
  "06415001",
  "06445001",
  "06485001",
  "06485002",
  "06490001",
  "06495001",
  "06605001",
  "06605401",
  "06610001",
  "06610002",
  "06610101",
  "06610401",
  "06650001",
  "06690001",
  "06710001",
  "06805001",
  "17180736",
  "17234866",
  "23955101",
  "24330101",
  "24580101",
  "24904131",
  "24935131",
  "24940101",
  "26218101",
  "26300101",
  "26330101",
  "26331101",
  "02634937",
  "26835101",
  "26400101",
  "26581101",
  "26846101",
  "26950101",
  "56400184",
  "97000000",
  "97000001",
  "16300N86",
  "16400W74",
  "1640BB52",
  "173N98OE",
  "17400N74",
  "1740BC52",
  "1740BZ49",
  "1740BZ52",
  "1740BZ53",
  "1745BB55",
  "1745BC55",
  "1745BZ55",
  "1745BZ58",
  "306101GS",
  "6050003B",
];
