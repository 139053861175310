import { memo, useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { TextField, debounce } from "@mui/material";
import { Row, Col, Button } from "react-bootstrap";
import { AiOutlineSave } from "react-icons/ai";
import { useForm, Controller } from "react-hook-form";
import { formStyle } from "../../../theming/formStyle";
import CustomersTable from "./CustomersTable";

function AdvancedSearchPopup({
  open,
  customersList,
  fuzzyQuery,
  total,
  setOpen,
  searchCustomersByAdvanced,
  getCustomerData,
}) {
  // Refs
  const searchInputRef = useRef();
  const nameInputRef = useRef();
  const mainCityInputRef = useRef();
  const mainStateInputRef = useRef();
  const aftermarketNameInputRef = useRef();

  const { control, getValues, setValue, reset } = useForm();

  const [selectedId, setSelectedId] = useState(0);
  const [page, setPage] = useState(0);

  useEffect(() => {
    reset();
    setValue("search", fuzzyQuery);
    setPage(0);
    setSelectedId(0);
  }, [fuzzyQuery]);

  // Set delay so that request is sent after user finishes input
  const handleFilterChange = debounce(() => {
    const { search, name, city, state, network_name } = getValues();
    searchCustomersByAdvanced({
      search,
      name,
      state,
      city,
      network_name,
    });
    setPage(0);
  }, 500); // This value (0.5s) can be adjusted as if needed

  const handlePageChange = (newPage) => {
    const { search, name, city, state, network_name } = getValues();

    searchCustomersByAdvanced({
      search,
      name,
      state,
      city,
      network_name,
      page: newPage + 1,
    });

    setPage(newPage);
  };

  const onSelectedIdChanged = (id) => {
    setSelectedId(id);
  };

  const onApply = () => {
    getCustomerData({ id: selectedId, customers: customersList });
    setOpen(false);
  };

  return (
    <Modal
      show={open}
      aria-labelledby="contained-modal-title-vcenter"
      size="xl"
      centered
      onHide={() => setOpen(false)}
    >
      <Modal.Header closeButton className="pt-4 px-5">
        <Modal.Title>
          <h3 className="h5">Customer Advanced Search</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-5">
        <Row>
          <Col>
            <Controller
              name="search"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  type="text"
                  label="Fuzzy query"
                  name="search"
                  value={value}
                  style={formStyle.inputStyle}
                  InputLabelProps={formStyle.inputLabelProps}
                  onChange={(e) => {
                    onChange(e.target.value);
                    handleFilterChange({ search: e.target.value });
                  }}
                  error={!!error}
                  helperText={error ? error.message : null}
                  inputRef={searchInputRef}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      nameInputRef.current.focus();
                    }
                  }}
                />
              )}
            />
          </Col>
          <Col>
            <Controller
              name="name"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  type="text"
                  label="Customer Name"
                  name="name"
                  value={value}
                  style={formStyle.inputStyle}
                  InputLabelProps={formStyle.inputLabelProps}
                  onChange={(e) => {
                    onChange(e.target.value);
                    handleFilterChange({ name: e.target.value });
                  }}
                  error={!!error}
                  helperText={error ? error.message : null}
                  inputRef={nameInputRef}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      mainCityInputRef.current.focus();
                    }
                  }}
                />
              )}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <Controller
              name="city"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  type="text"
                  label="Shipping City"
                  name="city"
                  value={value}
                  style={formStyle.inputStyle}
                  InputLabelProps={formStyle.inputLabelProps}
                  onChange={(e) => {
                    onChange(e.target.value);
                    handleFilterChange({ city: e.target.value });
                  }}
                  error={!!error}
                  helperText={error ? error.message : null}
                  inputRef={mainCityInputRef}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      mainStateInputRef.current.focus();
                    }
                  }}
                />
              )}
            />
          </Col>
          <Col>
            <Controller
              name="state"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  type="text"
                  label="Shipping State"
                  name="state"
                  value={value}
                  style={formStyle.inputStyle}
                  InputLabelProps={formStyle.inputLabelProps}
                  onChange={(e) => {
                    onChange(e.target.value);
                    handleFilterChange({ state: e.target.value });
                  }}
                  error={!!error}
                  helperText={error ? error.message : null}
                  inputRef={mainStateInputRef}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      aftermarketNameInputRef.current.focus();
                    }
                  }}
                />
              )}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <Controller
              name="network_name"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  type="text"
                  label="Aftermarket Customer Name"
                  name="network_name"
                  value={value}
                  style={formStyle.inputStyle}
                  InputLabelProps={formStyle.inputLabelProps}
                  onChange={(e) => {
                    onChange(e.target.value);
                    handleFilterChange({ network_name: e.target.value });
                  }}
                  error={!!error}
                  helperText={error ? error.message : null}
                  inputRef={aftermarketNameInputRef}
                />
              )}
            />
          </Col>
        </Row>
        <CustomersTable
          selectedId={selectedId}
          setSelectedId={onSelectedIdChanged}
          total={total}
          customers={customersList}
          page={page}
          setPage={handlePageChange}
        />
        <div
          className="d-flex justify-content-end align-items-center"
          style={{ marginTop: "-36px" }}
        >
          <div className="advanced-customer-search-popup__guide">
            {selectedId
              ? "You have selected one of the customer of the list above"
              : "You can select one of the customer of the list above"}
          </div>

          <Button
            variant="primary"
            className="ms-5"
            disabled={!selectedId}
            onClick={onApply}
          >
            <span className="me-2">Apply</span>
            <AiOutlineSave />
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default memo(AdvancedSearchPopup);
