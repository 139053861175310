import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import VendorCategoryDiscountSchedules from "../../atoms/VendorCategoryDiscountSchedules";
import "./CustomerDetails.scss";
import {
  vendorDiscounts,
  getDiscountPrograms,
} from "../../../store/slices/customer/customerSlice";

const CustomerDetails = () => {
  const dispatch = useDispatch();

  const {
    customerData: {
      customerId,
      contactName,
      contactPhone,
      contactFax,
      billingAdressCombo,
      shippingAddressCombo,
      creditLimit,
      prepaidWeight,
      terms,
    },
    discountSchedules: { total, data },
    discountPrograms,
  } = useSelector((state) => state.customer);

  const [page, setPage] = useState(0);

  useEffect(() => {
    if (customerId) {
      dispatch(vendorDiscounts({ customerId, page: page + 1 }));
      dispatch(getDiscountPrograms(customerId));
    }
  }, [customerId, page]);

  return (
    <Card className="CustomerDetails">
      <Card.Body>
        <Row>
          <Col sm={6}>
            <Card.Title>Billing Address</Card.Title>
            <Card.Text className="read-only-info">
              {billingAdressCombo}
            </Card.Text>
          </Col>
          <Col sm={6}>
            <Card.Title>Shipping Address</Card.Title>
            <Card.Text className="read-only-info">
              {shippingAddressCombo}
            </Card.Text>
          </Col>
        </Row>
        <Row className="mt-5">
          <Card.Title>Contact</Card.Title>
          <Col md={4}>
            <Row>
              <Col sm="4">
                <Card.Text className="read-only-label text-end">
                  Name:
                </Card.Text>
              </Col>
              <Col sm="8">
                <Card.Text className="read-only-info">
                  {contactName ? contactName : "No value"}
                </Card.Text>
              </Col>
            </Row>
          </Col>
          <Col md={4}>
            <Row>
              <Col sm="4">
                <Card.Text className="read-only-label text-end">
                  Phone:
                </Card.Text>
              </Col>
              <Col sm="8">
                <Card.Text className="read-only-info">
                  {contactPhone ? contactPhone : "No value"}
                </Card.Text>
              </Col>
            </Row>
          </Col>
          <Col md={4}>
            <Row>
              <Col sm="4">
                <Card.Text className="read-only-label text-end">Fax:</Card.Text>
              </Col>
              <Col sm="8">
                <Card.Text className="read-only-info">
                  {contactFax ? contactFax : "No value"}
                </Card.Text>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-5">
          <Card.Title>Account Details</Card.Title>
          <Col md={4}>
            <Row>
              <Col sm="4">
                <Card.Text className="read-only-label text-end">
                  Prepaid Weight:
                </Card.Text>
              </Col>
              <Col sm="8">
                <Card.Text className="read-only-info">
                  {prepaidWeight}
                </Card.Text>
              </Col>
            </Row>
          </Col>
          <Col md={4}>
            <Row>
              <Col sm="4">
                <Card.Text className="read-only-label text-end">
                  Credit Limit:
                </Card.Text>
              </Col>
              <Col sm="8">
                <Card.Text className="read-only-info">
                  ${creditLimit ? creditLimit : "No value"}
                </Card.Text>
              </Col>
            </Row>
          </Col>
          <Col md={4}>
            <Row>
              <Col sm="4">
                <Card.Text className="read-only-label text-end">
                  Terms:
                </Card.Text>
              </Col>
              <Col sm="8">
                <Card.Text className="read-only-info">{terms}</Card.Text>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-5">
          <Card.Title>Vendor Category Discounts</Card.Title>
          <VendorCategoryDiscountSchedules
            total={total}
            data={data}
            programs={discountPrograms}
            page={page}
            setPage={setPage}
          />
        </Row>
      </Card.Body>
    </Card>
  );
};

export default CustomerDetails;
