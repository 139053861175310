import React, { memo, useRef, useEffect } from "react";
import { Checkbox } from "@mui/material";
import { formStyle } from "../../../theming/formStyle";
import { Controller } from "react-hook-form";

function FaxEmailCheckBox({
  control,
  name,
  label,
  rules,
  refElement,
  changeRefElement,
}) {
  const cbRef = useRef();

  useEffect(() => {
    if (refElement === name) {
      cbRef.current.focus();
    }
  }, [refElement, name]);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <>
          <Checkbox
            checked={value}
            inputRef={cbRef}
            onChange={onChange}
            sx={formStyle.checkboxStyle}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                changeRefElement();
              }
            }}
          />
          <label>{label}</label>
        </>
      )}
    />
  );
}

export default memo(FaxEmailCheckBox);
