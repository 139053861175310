import { memo, useState, useEffect } from "react";
import TableSortLabel from "@mui/material/TableSortLabel";
import DataGridFilters from "./DataGridFilters";
import Spinner from "react-bootstrap/Spinner";

import {
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Table,
  Tooltip,
} from "@mui/material";

import Tr from "./Tr";
import StyledTableCell from "./StyledTableCell";
import Paginator from "../Paginator/Paginator";

/**
 *  Memo function to avoid this component rerender at least that the list
 *  or one of the props has been updated
 */
function areEqual(prevProps, nextProps) {
  const listsAreEqual = prevProps.dataList.find((prevList, index) => {
    if (prevList?.id !== nextProps.dataList[index]?.id) {
      return false;
    }
    return true;
  });

  const headerIsEqual = prevProps.headerAttributes.find((prevList, index) => {
    if (prevList?.id !== nextProps.dataList[index]?.id) {
      return false;
    }
    return true;
  });

  if (
    headerIsEqual &&
    listsAreEqual &&
    prevProps.hideId === nextProps.hideId &&
    prevProps.rowsPerPage === nextProps.rowsPerPage &&
    prevProps.total === nextProps.total &&
    prevProps.defaultOrder === nextProps.defaultOrder &&
    prevProps.defaultOrderBy === nextProps.defaultOrderBy
  ) {
    return true;
  }
  return false;
}

function DataGrid({
  headerAttributes,
  dataList,
  hideId,
  rowsPerPage,
  total,
  defaultOrder,
  defaultOrderBy,
  fetchData,
  dateFilter,
}) {
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("desc");
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [customFilters, setCustomFilters] = useState([]);

  /**
   * Function to set the order of a column
   */
  const handleRequestSort = async ({ property }) => {
    setLoading(true);
    const isAsc = orderBy === property && order === "asc";
    await fetchData({
      page,
      orderBy: property,
      sortOrder: isAsc ? "desc" : "asc",
      customParameters: customFilters,
    });

    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setLoading(false);
  };

  const handleFilterChange = async (customParameters) => {
    setPage(0);
    setLoading(true);
    await fetchData({
      page: 0,
      orderBy,
      sortOrder: order,
      customParameters,
    });
    setCustomFilters(customParameters);
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      await fetchData({ orderBy: defaultOrderBy, sortOrder: defaultOrder });
      setLoading(false);
    })();
  }, [fetchData, defaultOrder, defaultOrderBy]);

  useEffect(() => {
    if (defaultOrder) {
      setOrder(defaultOrder);
    }
  }, [defaultOrder]);

  useEffect(() => {
    if (defaultOrderBy) {
      setOrderBy(defaultOrderBy);
    }
  }, [defaultOrderBy]);

  return (
    <section>
      <DataGridFilters
        headerAttributes={headerAttributes}
        customFilters={customFilters}
        dateFilter={dateFilter}
        setLoading={(state) => {
          setLoading(state);
        }}
        fetchData={handleFilterChange}
      />
      <TableContainer component={Paper} style={{ position: "relative" }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {headerAttributes ? (
                headerAttributes.map((th) => {
                  if (th?.hidden) {
                    return <></>;
                  } else {
                    const tableCell = (
                      <StyledTableCell style={{ width: th.width }} key={th.id}>
                        {th?.sortable ? (
                          <TableSortLabel
                            active={orderBy === th.id}
                            direction={orderBy === th.id ? order : "asc"}
                            onClick={() =>
                              handleRequestSort({
                                property: th.id,
                                backendSortable: th.backendSortable,
                              })
                            }
                          >
                            {th.label}
                          </TableSortLabel>
                        ) : (
                          th.label
                        )}
                      </StyledTableCell>
                    );

                    return th?.tooltip ? (
                      <Tooltip title={th.tooltip} placement="top" arrow>
                        {tableCell}
                      </Tooltip>
                    ) : (
                      tableCell
                    );
                  }
                })
              ) : (
                <></>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading && (
              <>
                {dataList.map((row) => (
                  <Tr key={row.id} data={row} hideId={hideId} />
                ))}
              </>
            )}
          </TableBody>
        </Table>
        {loading && (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ width: "100%", minHeight: 150 }}
          >
            <Spinner animation="grow" variant="primary" />
          </div>
        )}
        <Paginator
          rowsPerPage={rowsPerPage}
          totalOrders={total}
          page={page}
          setPage={async (p) => {
            setPage(p);
            setLoading(true);
            await fetchData({
              page: p,
              orderBy,
              sortOrder: order,
              customParameters: customFilters,
            });
            setLoading(false);
          }}
        />
      </TableContainer>
    </section>
  );
}

export default memo(DataGrid, areEqual);
