import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CustomizedSelectField = styled(TextField)`
  width: 100%;
  height: 48px;
  > div {
    height: 48px;
  }
  .MuiSelect-select {
    padding: 14px;
  }
`;

export const formStyle = {
  inputStyle: {
    width: "100%",
    borderRadius: "0px",
    // gap: '0.5rem',
    // fontSize: 14
  },
  // inputReadOnly: {
  //     width: '100%',
  //     borderRadius: '0px'
  // },
  inputLabelProps: {
    shrink: true,
  },
  selectStyle: {
    height: "48px",
  },
  checkboxStyle: {
    "& .MuiSvgIcon-root": { fontSize: 26 },
  },
};
