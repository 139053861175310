import React, { memo, useRef, useEffect } from "react";
import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { formStyle } from "../../../theming/formStyle";

function FaxEmailTextField({
  control,
  name,
  label,
  rules,
  rows,
  refElement,
  changeRefElement,
  externalError,
  externalErrorMsg,
}) {
  const inputRef = useRef();

  useEffect(() => {
    if (refElement === name) {
      inputRef.current.focus();
    }
  }, [refElement, name]);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          type="text"
          label={label}
          name={name}
          style={formStyle.inputStyle}
          InputLabelProps={formStyle.inputLabelProps}
          helperText={
            error ? error.message : externalError ? externalErrorMsg : null
          }
          error={error || externalError ? true : false}
          onChange={onChange}
          value={value}
          inputRef={inputRef}
          multiline={rows ? true : false}
          rows={rows}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              changeRefElement();
            }
          }}
          onFocus={() => changeRefElement(name)}
        />
      )}
    />
  );
}

export default memo(FaxEmailTextField);
