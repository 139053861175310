import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSectionRef, setFieldRef } from "../store/slices/refsSlice";

function useFocusEvent(nextSection, nextField, reftoJumpOn) {
  // Redux
  const dispatch = useDispatch();
  const { fieldRef } = useSelector((state) => state.refs);

  useEffect(() => {
    if (fieldRef === nextField) {
      reftoJumpOn.current.focus();
    }
  }, [fieldRef]);

  const onClickHandler = () => {
    dispatch(setSectionRef(""));
    dispatch(setFieldRef(""));
  };

  return { onClickHandler };
}

export default useFocusEvent;
