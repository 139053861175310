import { memo } from "react";
import { useSelector } from "react-redux";
import { AiOutlineCloseCircle } from "react-icons/ai";

function BackOrderMessage() {
  // Redux
  const { hasBackOrders } = useSelector((state) => state.items);

  return (
    <div>
      {hasBackOrders && (
        <div className="text-danger">
          <AiOutlineCloseCircle />
          <span>You have a least one product with one backorder</span>
        </div>
      )}
    </div>
  );
}

export default memo(BackOrderMessage);
