import React from "react";
import PropTypes from "prop-types";
import "./Heading.scss";
import "../BreadCrumbs/BreadCrumbs.scss";
import { Link } from "react-router-dom";

const Heading = (props) => {
  return (
    <div className="Heading my-4">
      <h1 className="title">{props.title}</h1>
      {props.subtitle && (
        <h4 className="subtitle mt-4">
          {props.subtitle}
          {props.identifier && (
            <span className="identifier ms-5">ID# {props.identifier} </span>
          )}
        </h4>
      )}
      {props.links && (
        <div className="BreadCrumbs mt-4">
          {props.links &&
            props.links.map((link, index) => {
              return (
                <span key={index}>
                  <Link to={link.url}>{link.label}</Link>
                  <span className="mx-3">/</span>
                </span>
              );
            })}
          {props.currentLabel && <a>{props.currentLabel}</a>}
        </div>
      )}
    </div>
  );
};

Heading.propTypes = {
  title: PropTypes.string, // Required
  subtitle: PropTypes.string, // Not required
  identifier: PropTypes.node, // Not required
};

export default Heading;
