import axios from "axios";
import { endpoints } from "./endpoints";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "x-csrftoken";

export const apiDelete = {
  lineItem: deleteLineItem,
  deleteSubOrder,
};

function deleteLineItem(lineItemId, customerOrderNum) {
  return () =>
    axios.delete(
      endpoints.MITCO_API_URL +
        "/v1" +
        endpoints.SUB_ORDER_READ +
        customerOrderNum +
        endpoints.SUBORDER_ITEMS_DELETE +
        lineItemId +
        "/",
      {
        withCredentials: true,
        headers: {
          Accept: "application/json",
        },
      },
    );
}

async function deleteSubOrder(id) {
  const response = await axios.delete(
    endpoints.MITCO_API_URL + "/v1" + endpoints.DELETE_SUB_ORDER + id,
    {
      withCredentials: true,
      headers: {
        Accept: "application/json",
      },
    },
  );
  return response;
}
