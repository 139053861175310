import { memo } from "react";

function CreditLimit({ creditLimit }) {
  return +creditLimit ? (
    <span style={{ marginTop: "15px", display: "inline-block" }}>
      {`Credit Limit: $${creditLimit}`}
    </span>
  ) : (
    <></>
  );
}

export default memo(CreditLimit);
