import React from "react";
import { useSelector } from "react-redux";
import { TextField } from "@mui/material";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import "./CustomerInfo.scss";
import { formStyle } from "../../../theming/formStyle";
import AfterMarket from "../../atoms/AfterMarket";
import XRefCode from "../../atoms/XRefCode";

const CustomerInfo = () => {
  // Redux
  const { customerData } = useSelector((state) => state.customer);

  const {
    aftermarketId,
    aftermarketName,
    billToDealer,
    hasXRefItems,
    itemXRefCode,
  } = customerData;

  return (
    <Col className="CustomerInfo">
      <Row className="mb-4 mt-4 pt-2 address-section">
        <Col>
          <Card.Title className="mb-4">Billing Address</Card.Title>
          <TextField
            id="filled-basic"
            multiline
            disabled
            rows={3}
            value={customerData?.billingAdressCombo || ""}
            className="input-read-only"
            style={formStyle.inputStyle}
            InputLabelProps={formStyle.inputLabelProps}
            InputProps={{
              readOnly: true,
            }}
          />
        </Col>
        <Col>
          <Card.Title className="mb-4">Shipping Address</Card.Title>
          <TextField
            id="filled-basic"
            multiline
            disabled
            rows={3}
            value={customerData?.shippingAddressCombo || ""}
            className="input-read-only"
            style={formStyle.inputStyle}
            InputLabelProps={formStyle.inputLabelProps}
            InputProps={{
              readOnly: true,
            }}
          />
        </Col>
      </Row>
      <Row className="mb-4 gs-4 contact-section">
        <Card.Title>Contact</Card.Title>
        <Col>
          <TextField
            readOnly={true}
            disabled
            label="Name"
            value={customerData?.contactName || ""}
            style={formStyle.inputStyle}
            className="input-read-only"
            InputLabelProps={formStyle.inputLabelProps}
            InputProps={{
              readOnly: true,
            }}
          />
        </Col>
        <Col>
          <TextField
            label="Phone"
            disabled
            value={customerData?.contactPhone || ""}
            style={formStyle.inputStyle}
            className="input-read-only"
            InputLabelProps={formStyle.inputLabelProps}
            InputProps={{
              readOnly: true,
            }}
          />
        </Col>
        <Col>
          <TextField
            label="Fax"
            disabled
            value={customerData?.contactFax || ""}
            style={formStyle.inputStyle}
            className="input-read-only"
            InputLabelProps={formStyle.inputLabelProps}
            InputProps={{
              readOnly: true,
            }}
          />
        </Col>
      </Row>
      <Row className="pt-4">
        {aftermarketId ? (
          <Col>
            <AfterMarket
              id={aftermarketId}
              name={aftermarketName}
              billToDealer={billToDealer}
            />
          </Col>
        ) : (
          <></>
        )}
        {hasXRefItems ? (
          <Col>
            <XRefCode code={itemXRefCode} />
          </Col>
        ) : (
          <></>
        )}
      </Row>
    </Col>
  );
};

export default CustomerInfo;
