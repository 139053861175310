import { useContext } from "react";
import { Button } from "@mui/material";
import { apiGet } from "../../api/apiGet";
import { LoginContext } from "../../contexts/LoginContext";
import { AlertMessageContext } from "../../components/atoms/AlertMessage/AlertMessage";

function MyAccount() {
  const { username, setAuthenticated } = useContext(LoginContext);
  const alertNotification = useContext(AlertMessageContext);

  const logOut = async () => {
    try {
      await apiGet.logOut();
      setAuthenticated(false);
    } catch (error) {
      alertNotification.handleOpen(
        "error-logging-out",
        "danger",
        `Error logging out. ${error}`,
        10000,
      );
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <div className="row w-100">
        <div className="col-md-6 mx-auto mt-5">
          <div className="flex-column d-flex align-items-center card card-body container-sm">
            <h3>My Account</h3>
            <h1>Welcome {username}!</h1>
            <Button
              onClick={logOut}
              color="inherit"
              className="text-capitalize"
            >
              <div className="text-primary">Log Out</div>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyAccount;
