import { memo } from "react";
import { useSelector } from "react-redux";
import {
  AiOutlineCheckCircle,
  AiOutlineCloseCircle,
  AiOutlineExclamationCircle,
} from "react-icons/ai";

import {
  AR_BALANCE_GOOD_STATUS,
  AR_BALANCE_MEDIUM_STATUS,
  AR_BALANCE_BAD_STATUS,
} from "../../../helpers/const";

function ARBalanceMessage() {
  const { standingMessage, standingStatus } = useSelector(
    (state) => state.aRBalance,
  );

  return (
    <span className={`ar-${standingStatus}-standing ar-standing-message`}>
      {standingStatus === AR_BALANCE_GOOD_STATUS && (
        <AiOutlineCheckCircle className="me-2" />
      )}
      {standingStatus === AR_BALANCE_MEDIUM_STATUS && (
        <AiOutlineExclamationCircle className="me-2" />
      )}
      {standingStatus === AR_BALANCE_BAD_STATUS && (
        <AiOutlineCloseCircle className="me-2" />
      )}
      {standingMessage}
    </span>
  );
}

export default memo(ARBalanceMessage);
