export const formattAccountBalanceInfo = (accountBalanceInfo) => {
  const clonedBalanceInfo = JSON.parse(JSON.stringify(accountBalanceInfo));

  if (clonedBalanceInfo.length > 0) {
    let period0 = 0;
    let period1 = 0;
    let period2 = 0;
    let period3 = 0;
    let period4 = 0;

    accountBalanceInfo.forEach((period) => {
      if (period.aging_period === 0) {
        period0 = Number(period.total_credit);
      }
      if (period.aging_period === 1) {
        period1 = Number(period.total_credit);
      }
      if (period.aging_period === 2) {
        period2 = Number(period.total_credit);
      }
      if (period.aging_period === 3) {
        period3 = Number(period.total_credit);
      }
      if (period.aging_period === 4) {
        period4 = Number(period.total_credit);
      }
    });

    return {
      total: (period0 + period1 + period2 + period3 + period4).toFixed(2),
      period0,
      period1,
      period2,
      period3,
      period4,
    };
  }

  return {
    total: 0,
    period0: 0,
    period1: 0,
    period2: 0,
    period3: 0,
    period4: 0,
  };
};
