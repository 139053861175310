import { memo, useCallback, useEffect, useState } from "react";
import Table from "../../atoms/Table/Table";
import Paginator from "../../atoms/Paginator/Paginator";

/**
 *  Memo function to avoid this component rerender at least that the list
 *  or one of the props has been updated
 */
function areEqual(prevProps, nextProps) {
  const customersAreEqual = prevProps.customers?.find(
    (prev, index) => prev?.id === nextProps.customers[index]?.id,
  );

  if (
    prevProps.setSelectedId === nextProps.setSelectedId &&
    prevProps.total === nextProps.total &&
    prevProps.selectedId === nextProps.selectedId &&
    prevProps.page === nextProps.page &&
    prevProps.setPage === nextProps.setPage &&
    customersAreEqual
  ) {
    return true;
  }
  return false;
}

function CustomersTable({
  setSelectedId,
  total,
  customers,
  selectedId,
  page,
  setPage,
  focusOnTable,
}) {
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    setDataList(
      customers?.map((customer) => ({
        id: customer.id,
        name: customer.name,
        shippingAddressCombo: customer.shippingAddressCombo,
        billingAdressCombo: customer.billingAdressCombo,
        aftermarketName: customer.aftermarketName,
        itemXRefCode: customer.itemXRefCode,
      })),
    );
  }, [customers]);

  const onSelectRow = useCallback(
    (id) => {
      setSelectedId(id);
    },
    [setSelectedId],
  );

  return (
    <>
      <div className="advanced-customer-search-popup__table mt-5">
        <Table
          selector="raddio"
          headerAttributes={[
            {
              id: "select",
              label: "Select",
            },
            {
              id: "id",
              label: "ID",
            },
            {
              id: "name",
              label: "Name",
            },
            {
              id: "shippingAddressCombo",
              label: "Shipping Address",
            },
            {
              id: "billingAdressCombo",
              label: "Billing Address",
            },
            {
              id: "aftermarketName",
              label: "Aftermarket",
            },
            {
              id: "itemXRefCode",
              label: "XCrossRef",
            },
          ]}
          dataList={dataList}
          onSelectRow={onSelectRow}
          selectedItem={selectedId}
        />
      </div>
      <Paginator
        rowsPerPage={10}
        totalOrders={total}
        page={page}
        setPage={(p) => setPage(p)}
      />
    </>
  );
}

export default memo(CustomersTable, areEqual);
