/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AlertMessageContext } from "../../atoms/AlertMessage/AlertMessage";
import { LoginContext } from "../../../contexts/LoginContext";
import { cleanApiMsg } from "../../../store/slices/apiErrors/apiErrorSlice";

export default function ApiMessage() {
  const dispatch = useDispatch();
  const { error, apiStatus } = useSelector((state) => state.apiError);

  // Context
  const alertNotification = useContext(AlertMessageContext);
  const { setAuthenticated } = useContext(LoginContext);

  useEffect(() => {
    if (error.id) {
      alertNotification.handleOpen(error.id, "danger", error.message, 5000);

      if (apiStatus === 401) {
        setAuthenticated(false);
      }

      dispatch(cleanApiMsg());
    }
  }, [error.id]);

  return <></>;
}
