import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#007DC0",
    color: theme.palette.common.white,
    position: "sticky",
    top: 0,
    zIndex: "100",
    padding: "0px 4px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    "& > span": {
      color: theme.palette.common.white,
      "& > svg": {
        color: "rgba(0, 0, 0, 0.6)",
      },
      "&.Mui-active > svg": {
        opacity: 0.5,
      },
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderRight: "1px solid rgb(240, 240, 240)",
  },
}));

export default StyledTableCell;
