import { memo, useCallback, useEffect, useState } from "react";
import Table from "../../atoms/Table/Table";
import Paginator from "../../atoms/Paginator/Paginator";

/**
 *  Memo function to avoid this component rerender at least that the list
 *  or one of the props has been updated
 */
function areEqual(prevProps, nextProps) {
  const addressesAreEqual = prevProps.dropShipAddresses.find(
    (prevAddress, index) => {
      if (prevAddress?.id !== nextProps.dropShipAddresses[index]?.id) {
        return false;
      }
      return true;
    },
  );

  if (
    prevProps.setSelectedAddress === nextProps.setSelectedAddress &&
    prevProps.totalAddresses === nextProps.totalAddresses &&
    prevProps.selectedAddress === nextProps.selectedAddress &&
    prevProps.focusOnDataTable === nextProps.focusOnDataTable &&
    prevProps.nextFocusRef === nextProps.nextFocusRef &&
    prevProps.page === nextProps.page &&
    prevProps.setPage === nextProps.setPage &&
    addressesAreEqual
  ) {
    return true;
  }
  return false;
}

function DropShipTable({
  setSelectedAddress,
  totalAddresses,
  dropShipAddresses,
  selectedAddress,
  focusOnDataTable,
  nextFocusRef,
  page,
  setPage,
}) {
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    setDataList(
      dropShipAddresses.map((d) => ({
        id: d.id,
        name: d.name,
        street: d.street,
        street2: d.route,
        city: d.city,
        state: d.state,
        zip: d.zipCode,
      })),
    );
  }, [dropShipAddresses]);

  return (
    <>
      <div className="drop-ship-popup__table mt-5">
        <Table
          selector="raddio"
          headerAttributes={[
            {
              id: "select",
              label: "Select",
            },
            {
              id: "name",
              label: "Name",
            },
            {
              id: "street",
              label: "Street",
            },
            {
              id: "street2",
              label: "Street pt. 2",
            },
            {
              id: "city",
              label: "City",
            },
            {
              id: "state",
              label: "State",
            },
            {
              id: "zip",
              label: "Zip",
            },
          ]}
          dataList={dataList}
          onSelectRow={useCallback((id) => setSelectedAddress(id), [])}
          selectedItem={selectedAddress}
          hideId={true}
          getFocus={focusOnDataTable}
          nextFocusRef={nextFocusRef}
        />
      </div>
      <Paginator
        rowsPerPage={50}
        totalOrders={totalAddresses}
        page={page}
        setPage={(p) => setPage(p)}
      />
    </>
  );
}

export default memo(DropShipTable, areEqual);
