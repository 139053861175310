import { createSlice } from "@reduxjs/toolkit";
import { getErrorMessage } from "./apiError";

const initialState = {
  error: {
    id: "",
    message: "",
  },
  success: {
    id: "",
    message: "",
  },
  apiStatus: 0,
};

export const apiErrorSlice = createSlice({
  name: "apiErrorSlice",
  initialState: initialState,
  reducers: {
    setErrorApiMsg: (state, action) => {
      state.error.id = action.payload.id;
      state.error.message = getErrorMessage(action.payload.axiosData);
      state.apiStatus = action.payload.axiosData?.axiosError?.request?.status;
    },
    setSuccessApiMsg: (state, action) => {
      /* state.success.id = action.payload.id
      state.success.message = action.payload.message */
    },
    cleanApiMsg: () => {
      return initialState;
    },
  },
});

export const { setErrorApiMsg, setSuccessApiMsg, cleanApiMsg } =
  apiErrorSlice.actions;

export default apiErrorSlice.reducer;
