import { memo, useState } from "react";
import { Modal, Button } from "react-bootstrap";

function ConfirmationAlertBySteps({
  steps,
  show,
  close,
  handleConfirmEvent,
  handledCancelEvent,
  title,
  confirmationLabel,
}) {
  const [step, setStep] = useState(1);
  return (
    <Modal
      show={show}
      centered
      aria-labelledby="contained-modal-title-vcenter"
      onHide={() => {
        close();
        setStep(1);
      }}
    >
      <Modal.Header closeButton className="p-5">
        <Modal.Title>
          <h3 className="h5">{title}</h3>
        </Modal.Title>
      </Modal.Header>
      {steps.map((s, index) => (
        <>
          {index + 1 === step && (
            <Modal.Body key={s.id} className="p-5">
              {s.content}
            </Modal.Body>
          )}
        </>
      ))}
      <Modal.Footer>
        <Button
          variant={confirmationLabel == "Delete" ? "primary" : "danger"}
          onClick={handledCancelEvent}
        >
          Cancel
        </Button>
        {steps.length > 1 && step > 1 && (
          <Button
            variant="primary"
            onClick={() => setStep((prev) => (prev = prev - 1))}
          >
            Go Back
          </Button>
        )}
        {step < steps.length && (
          <Button
            variant="primary"
            onClick={() => setStep((prev) => (prev = prev + 1))}
          >
            Continue
          </Button>
        )}
        {step === steps.length && (
          <Button
            variant={confirmationLabel == "Delete" ? "danger" : "primary"}
            onClick={handleConfirmEvent}
          >
            {confirmationLabel ? confirmationLabel : "Yes"}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default memo(ConfirmationAlertBySteps);
