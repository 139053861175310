import { createSlice } from "@reduxjs/toolkit";

export const headerSlice = createSlice({
  name: "headerSlice",
  initialState: {
    savedAsOrder: false,
    savedAsQuote: false,
    cleaningAll: false,
  },
  reducers: {
    setSavedAsOrder: (state, action) => {
      return {
        ...state,
        savedAsOrder: action.payload,
      };
    },
    setSavedAsQuote: (state, action) => {
      return {
        ...state,
        savedAsQuote: action.payload,
      };
    },
    setCleaningAll: (state) => {
      return {
        ...state,
      };
    },
  },
});

export const { setSavedAsOrder, setSavedAsQuote, setCleaningAll } =
  headerSlice.actions;
export default headerSlice.reducer;
