import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";

function DirectShipPopup() {
  // Local states
  const [open, setOpen] = useState(false);

  // Redux
  const { isDirectShip } = useSelector((state) => state.freight);
  const { hasBackOrders } = useSelector((state) => state.items);

  useEffect(() => {
    if (isDirectShip && hasBackOrders) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [isDirectShip, hasBackOrders]);

  return (
    <Modal
      show={open}
      size="md"
      onHide={() => setOpen(false)}
      centered
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton className="p-5">
        <Modal.Title>
          <h3 className="h5">Backorders Warning</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-5">
        <div className="text-danger">
          <span>
            You have at least one product with one backorder. Please uncheck the
            direct ship option or clean the BO fields on each item.
          </span>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default DirectShipPopup;
