import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Heading from "../../components/sections/Heading/Heading";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import DataGrid from "../../components/atoms/Table/DataGrid";
import { ORDER_STATUS } from "../../helpers/const";
import { getOrderListByParameters } from "../../store/slices/subOrderSlice";
import LoaderSpinner from "../../components/atoms/LoaderSpinner";

const headerAttributes = [
  {
    id: "OrderNumber",
    label: "Order #",
    tooltip: "Order Number",
    sortable: true,
    searchable: {
      label: "Search by Order Number",
      type: "text",
      width: 2,
    },
    width: "5%",
  },
  {
    id: "po",
    label: "PO",
    sortable: false,
  },
  {
    id: "dateOfOrder",
    label: "Date",
    tooltip: "Date of Order",
    sortable: true,
    width: "10%",
  },
  {
    id: "total",
    label: "Total",
    sortable: true,
  },
  {
    id: "agent",
    label: "Agent",
    sortable: true,
  },
  {
    id: "warehouseName",
    label: "Wh",
    tooltip: "Warehouse Name",
    sortable: true,
    width: "5%",
  },
  {
    id: "customerNum",
    label: "",
    sortable: true,
    searchable: {
      label: "Search by Customer Number",
      type: "text",
      width: 2,
    },
    hidden: true,
  },
  {
    id: "customer",
    label: "Customer",
    sortable: true,
    searchable: {
      label: "Search by Customer Name",
      type: "text",
      width: 2,
    },
  },
  {
    id: "dropShipAddress",
    label: "Drop Ship Address",
    sortable: false,
  },
  {
    id: "comments",
    label: "Comments",
    sortable: false,
  },
  {
    id: "status",
    label: "Status",
    sortable: true,
    searchable: {
      label: "Search by Status",
      type: "select",
      options: ORDER_STATUS,
      width: 2,
    },
  },
  {
    id: "details",
    label: "Details",
    sortable: false,
  },
];

const Orders = () => {
  const [dataList, setDataList] = useState([]);
  const [totalOrders, setTotalOrders] = useState(0);

  // Redux
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.loaderSpinner);

  const returnRealAttributeName = (attr) => {
    switch (attr) {
      case "OrderNumber":
        return "suborder_num";
      case "bol":
        return "bill_of_lading";
      case "customer":
        return "customer_name";
      case "dateOfOrder":
        return "date_of_order";
      case "warehouseName":
        return "warehouse_id";
      default:
        return attr;
    }
  };

  const fetchOrders = useCallback(
    async ({
      orderBy,
      sortOrder,
      page,
      custOrderNum,
      custNum,
      custName,
      status,
      from,
      to,
    }) => {
      dispatch(
        getOrderListByParameters({
          customerOrderNum: custOrderNum,
          page: page + 1,
          customerNum: custNum,
          customerName: custName,
          orderBy: returnRealAttributeName(orderBy),
          sortOrder: sortOrder === "desc" ? "dsc" : "asc",
          orderStatus: status,
          from,
          to,
        }),
      ).then((res) => {
        if (!res?.error) {
          setTotalOrders(res.payload.count);
          if (res?.payload) {
            setDataList(
              res?.payload.results.map((d) => ({
                id: d.customer_order_num,
                OrderNumber: d.customer_order_num,
                po: d?.customer_po,
                dateOfOrder: d.date_of_order,
                total: d.total,
                agent: d.took_by,
                warehouseName: d.warehouse_name,
                customer: d.customer?.name ? (
                  <Link to={`/customers/${d.customer_id}`}>
                    {d.customer.name} | {d.customer_id}
                  </Link>
                ) : (
                  <span></span>
                ),
                dropShipAddress: d?.freight?.drop_ship_address
                  ?.drop_ship_address_id
                  ? d.freight.drop_ship_address.address.city +
                    d.freight.drop_ship_address.address.state
                  : "N/A",
                comments:
                  d?.freight?.comments?.length > 0
                    ? d.freight.comments.join(" ")
                    : "",
                status: ORDER_STATUS.find((item) => item.id === d.status)
                  ?.label,
                actions: (
                  <Link to={`/sub-order/${d.customer_order_num}`}>View</Link>
                ),
              })),
            );
          }
        }
      });
    },
    [],
  );

  const prepareFetchOrders = useCallback(
    async ({ page, orderBy, sortOrder, customParameters }) => {
      let custOrderNum = "";
      let customerNum = "";
      let customerName = "";
      let status = "";
      let from = null;
      let to = null;

      if (customParameters) {
        customParameters.forEach((c) => {
          if (c?.id === "OrderNumber") {
            custOrderNum = c.value;
          }

          if (c?.id === "customerNum") {
            customerNum = c.value;
          }

          if (c?.id === "customer") {
            customerName = c.value;
          }

          if (c?.id === "status") {
            status = c.value;
          }

          if (c?.id === "dates") {
            from = dayjs(c.value[0]).format("YYYY-MM-DD");
            to = dayjs(c.value[1]).format("YYYY-MM-DD");
          }
        });
      }

      await fetchOrders({
        page,
        orderBy,
        sortOrder,
        custOrderNum,
        custNum: customerNum,
        custName: customerName,
        status,
        from,
        to,
      });
    },
    [fetchOrders],
  );

  return (
    <div>
      <Heading title="Orders" />
      <div className="mt-5">
        <div className="mt-5">
          <DataGrid
            headerAttributes={headerAttributes}
            dataList={dataList}
            hideId={true}
            rowsPerPage={50}
            total={totalOrders}
            defaultOrder="desc"
            defaultOrderBy="OrderNumber"
            fetchData={prepareFetchOrders}
            dateFilter={{
              show: true,
              inputs: [
                {
                  label: "From",
                  name: "from",
                },
                {
                  label: "To",
                  name: "to",
                },
              ],
            }}
          />
        </div>
      </div>
      <LoaderSpinner loading={loading} />
    </div>
  );
};

export default Orders;
